import {
  Button,
  HStack,
  IconButton,
  InputGroup,
  InputRightElement,
  Popover,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  VStack,
} from '@chakra-ui/react'
import {
  faPaintRoller,
  faPlus,
  faSwatchbook,
  faTrash,
} from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { GammaTooltip } from '@gamma-app/ui'
import { Trans, t } from '@lingui/macro'
import { useCallback, useRef } from 'react'

import {
  Theme,
  ThemeColor,
  ThemeColorGradient,
  ThemeColorSolid,
} from 'modules/theming/types'
import {
  checkThemeColorsEqual,
  getThemeAccentColors,
  getThemeColorLabel,
  getThemeColorSolid,
  getThemePalette,
  isThemeColorSolid,
} from 'modules/theming/utils/colors'
import { brightenColor, isColorDark } from 'utils/color'

import { DEFAULT_ANGLE, LinearGradientPicker } from './LinearGradientPicker'
import { DEFAULT_COLOR, SolidColorPicker } from './SolidColorPicker'
import { ThemeColorIcon } from './ThemeColorIcon'
import { ThemeColorPickerInput } from './ThemeColorPickerInput'

export type ThemeColorPickerProps = {
  theme: Theme
  color?: ThemeColor
  updateColor: (color: ThemeColor | undefined) => void
  defaultColor?: ThemeColor
  palette?: 'theme' | 'accent' | 'none'
  solidOnly?: boolean
  initialFocusRef?: React.RefObject<HTMLInputElement>
}

export const ThemeColorPicker = (props: ThemeColorPickerProps) => {
  const initialFocusRef = useRef<HTMLInputElement | null>(null)

  return (
    <Popover
      isLazy
      strategy="fixed" // Ensure it goes over the theme drawer modal and breaks out of scrolling container
      modifiers={[
        {
          name: 'preventOverflow',
          options: {
            altAxis: true, // Checks for vertical overflow in addition to horizontal. If necessary, will overlap content to prevent overflow
          },
        },
      ]}
      initialFocusRef={initialFocusRef}
    >
      <PopoverTrigger>
        <ThemeColorPickerInput
          {...props}
          canReset={Boolean(
            props.defaultColor &&
              props.color &&
              !checkThemeColorsEqual(props.defaultColor, props.color)
          )}
        />
      </PopoverTrigger>
      <PopoverContent
        zIndex="modalPopover"
        border="1px solid"
        borderColor="gray.100"
      >
        <PopoverBody>
          <ThemeColorPickerInner {...props} initialFocusRef={initialFocusRef} />
        </PopoverBody>
      </PopoverContent>
    </Popover>
  )
}

interface ThemeColorPickerSolidProps
  extends Omit<ThemeColorPickerProps, 'solidOnly'> {
  color?: ThemeColorSolid
  updateColor: (color: ThemeColorSolid | undefined) => void
  defaultColor?: ThemeColorSolid
}

export const ThemeColorPickerSolid = (props: ThemeColorPickerSolidProps) => {
  return <ThemeColorPicker {...props} solidOnly />
}

interface ThemeColorPickerRequiredProps
  extends Omit<ThemeColorPickerProps, 'defaultColor'> {
  color: ThemeColor
  updateColor: (color: ThemeColor) => void
}

export const ThemeColorPickerRequired = (
  props: ThemeColorPickerRequiredProps
) => {
  return <ThemeColorPicker {...props} />
}

const TAB_ORDER: ThemeColor['type'][] = ['solid', 'linear-gradient']

const ThemeColorPickerInner = (props: ThemeColorPickerProps) => {
  const { color, updateColor, solidOnly } = props
  const mode = color?.type || 'solid'
  const tabIndex = TAB_ORDER.indexOf(mode)

  const changeTab = useCallback(
    (index: number) => {
      const newMode = TAB_ORDER[index]
      if (mode === newMode) {
        return
      }
      const solidColor = getThemeColorSolid(color || DEFAULT_COLOR)
      if (newMode === 'solid') {
        updateColor({
          color: solidColor,
          ...color, // If we previously had a .color, prefer that
          type: 'solid',
        })
      } else if (newMode === 'linear-gradient') {
        updateColor({
          stops: [
            { color: solidColor, position: 0 },
            {
              color: brightenColor(
                solidColor,
                isColorDark(solidColor) ? 20 : -20
              ),
              position: 100,
            },
          ],
          angle: DEFAULT_ANGLE,
          ...color, // If we previously had gradient props, prefer those over these defaults
          type: 'linear-gradient',
        })
      }
      // setTabIndex(index)
    },
    [mode, color, updateColor]
  )

  if (solidOnly) {
    return <SolidColorPicker {...props} />
  }

  return (
    <Tabs
      variant="soft-rounded"
      size="sm"
      isFitted
      isLazy
      index={tabIndex}
      onChange={changeTab}
    >
      <TabList>
        <Tab>
          <HStack>
            <FontAwesomeIcon icon={faSwatchbook} fixedWidth />
            <Trans comment="Solid color, as opposed to gradient">Solid</Trans>
          </HStack>
        </Tab>
        <Tab>
          <HStack>
            <FontAwesomeIcon icon={faPaintRoller} fixedWidth />
            <Trans comment="Gradient color, as opposed to solid">
              Gradient
            </Trans>
          </HStack>
        </Tab>
      </TabList>
      <TabPanels>
        <TabPanel p={0} pt={4}>
          <SolidColorPicker {...props} />
        </TabPanel>
        <TabPanel p={0} pt={4}>
          <LinearGradientPicker
            {...props}
            color={color as ThemeColorGradient}
          />
        </TabPanel>
      </TabPanels>
    </Tabs>
  )
}

type ThemeColorsPickerProps = {
  colors: ThemeColor[]
  updateColors: (colors: ThemeColor[]) => void
  buttonLabel?: string
  maxColors?: number
  minColors?: number
  theme: Theme
  palette?: 'theme' | 'accent' | 'none'
  solidOnly?: boolean
}

export const ThemeColorsPicker = ({
  colors,
  updateColors,
  buttonLabel,
  maxColors = 10,
  minColors = 0,
  theme,
  palette,
  solidOnly,
}: ThemeColorsPickerProps) => {
  const removeColor = useCallback(
    (index: number) => {
      const newColors = [...colors]
      newColors.splice(index, 1)
      updateColors(newColors)
    },
    [colors, updateColors]
  )

  const updateColor = useCallback(
    (index: number, newColor: ThemeColor | undefined) => {
      if (!newColor) {
        removeColor(index)
        return
      }
      const newColors = [...colors]
      newColors[index] = newColor
      updateColors(newColors)
    },
    [colors, updateColors, removeColor]
  )

  const addColor = useCallback(() => {
    updateColors([...colors, DEFAULT_COLOR])
  }, [colors, updateColors])

  return (
    <>
      {colors.map((color, index) => (
        <InputGroup key={index} mb={2} css={{ isolation: 'unset' }}>
          <ThemeColorPickerRequired
            theme={theme}
            color={color}
            updateColor={(newColor) => updateColor(index, newColor)}
            palette={palette}
            solidOnly={solidOnly}
          />
          {colors.length > minColors && (
            <InputRightElement>
              <GammaTooltip label={<Trans>Delete color</Trans>} placement="top">
                <IconButton
                  aria-label={t`Delete`}
                  variant="ghost"
                  size="sm"
                  colorScheme="red"
                  onClick={() => removeColor(index)}
                  icon={<FontAwesomeIcon icon={faTrash} />}
                />
              </GammaTooltip>
            </InputRightElement>
          )}
        </InputGroup>
      ))}
      {colors.length < maxColors && (
        <Button
          onClick={addColor}
          variant="plain"
          size="sm"
          leftIcon={<FontAwesomeIcon icon={faPlus} />}
        >
          {buttonLabel || <Trans>Add color</Trans>}
        </Button>
      )}
    </>
  )
}

export const ThemePalette = ({
  theme,
  color,
  updateColor,
  solidOnly = false,
  palette = 'theme',
}: Omit<ThemeColorPickerProps, 'updateColor'> & {
  updateColor: (color: ThemeColor) => void
}) => {
  let themeColors: ThemeColor[] = []
  if (palette === 'theme') {
    themeColors = getThemePalette(theme, solidOnly)
  } else if (palette === 'accent') {
    themeColors = getThemeAccentColors(theme)
  }
  if (solidOnly) {
    themeColors = themeColors.filter(isThemeColorSolid)
  }

  if (themeColors.length === 0) {
    return null
  }
  return (
    <VStack align="start">
      <Text fontWeight="600" fontSize="sm" color="gray.500">
        <Trans comment="A set of brand colors">Theme palette</Trans>
      </Text>
      <HStack wrap="wrap">
        {themeColors.map((c, i) => (
          <ThemeColorIcon
            name={isThemeColorSolid(c) ? getThemeColorLabel(c) : undefined}
            color={c}
            key={i}
            onClick={updateColor}
            isActive={color && checkThemeColorsEqual(color, c)}
          />
        ))}
      </HStack>
    </VStack>
  )
}
