import { featureFlags } from 'modules/featureFlags'
import { brightenColor, isColorDark, lightenColor } from 'utils/color'

import { resetHeadingStylesheet } from './heading'

// These vars override the default shape colors defined in themeBases
export const getShapeColorOverrideVars = (
  color: string | undefined | null,
  fillOpacity: number = 1,
  transparentColor = 'var(--card-color)'
): Record<string, string | undefined> => {
  if (!color) return {}

  const borderColor =
    fillOpacity == 0 ? color : getBorderColorForBackground(color)

  let bgColor: string | undefined = color

  // In themes v3, you can have outline or semi-filled shapes
  if (fillOpacity === 0) {
    bgColor = undefined
  } else if (fillOpacity === 1) {
    bgColor = color
  } else {
    bgColor = `color-mix(in oklab, ${color} ${
      fillOpacity * 100
    }%, ${transparentColor})`
  }

  return {
    '--shape-bg-color-override': bgColor,
    '--shape-border-color-override': borderColor,
  }
}

export const getBorderColorForBackground = (color: string) => {
  return isColorDark(color)
    ? brightenColor(color, 10)
    : brightenColor(color, -10)
}

export const getTextColorVarsForBackground = (
  backgroundColor: string | undefined | null
) => {
  if (!backgroundColor)
    return { textColor: undefined, textColorVars: undefined }

  const textColor = isColorDark(backgroundColor) ? '#fff' : '#000'
  const textColorVars = getTextColorVars(textColor)

  return { textColor, textColorVars }
}

export const getTextColorVars = (textColor: string | undefined | null) => {
  if (!textColor) return
  return {
    '--body-color': textColor,
    '--heading-color': textColor,
    '--link-color': textColor,
    '--link-color-inverted': isColorDark(textColor) ? '#fff' : '#000',
    '--link-color-hover': isColorDark(textColor)
      ? lightenColor(textColor, 10)
      : lightenColor(textColor, -10),
  }
}

export const getShapeColorCss = (
  color: string | undefined | null,
  fillOpacity: number = 1
) => {
  if (!color) return
  const colorVars = getShapeColorOverrideVars(color, fillOpacity)
  if (fillOpacity === 0) return colorVars
  const { textColor, textColorVars } = getTextColorVarsForBackground(color)
  const headingCss = textColor ? resetHeadingStylesheet(textColor) : undefined
  return {
    ...colorVars,
    ...textColorVars,
    ...headingCss,
  }
}
