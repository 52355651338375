import {
  Button,
  IconButton,
  InputGroup,
  InputRightElement,
} from '@chakra-ui/react'
import { faPlus, faTrash } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { GammaTooltip } from '@gamma-app/ui'
import { Trans, t } from '@lingui/macro'
import { useCallback } from 'react'

import { MaybeHex } from 'modules/theming/components/ColorPickerMenu/types'
import { ColorPickerInput } from 'modules/tiptap_editor/components/panels/ColorPanel'

type ColorPalettePickerProps = {
  value: MaybeHex[]
  updateValue: (value: MaybeHex[]) => void
  buttonLabel?: string
  maxColors?: number
  minColors?: number
  defaultValue?: string
}

export const ColorPalettePicker = ({
  value,
  updateValue,
  buttonLabel,
  maxColors = 10,
  minColors = 0,
  defaultValue,
}: ColorPalettePickerProps) => {
  const updateColor = useCallback(
    (index: number, newColor: string) => {
      const newColors = [...value]
      newColors[index] = newColor
      updateValue(newColors)
    },
    [value, updateValue]
  )

  const addValue = useCallback(() => {
    updateValue([...value, null])
  }, [value, updateValue])

  const removeValue = useCallback(
    (index: number) => {
      const newColors = [...value]
      newColors.splice(index, 1)
      updateValue(newColors)
    },
    [value, updateValue]
  )

  return (
    <>
      {value.map((color, index) => (
        <InputGroup key={index} mb={2}>
          <ColorPickerInput
            value={color}
            updateValue={(newColor) => updateColor(index, newColor!)}
            defaultValue={defaultValue}
          />
          {value.length > minColors && (
            <InputRightElement>
              <GammaTooltip label={<Trans>Delete color</Trans>} placement="top">
                <IconButton
                  aria-label={t`Delete`}
                  variant="ghost"
                  colorScheme="red"
                  onClick={() => removeValue(index)}
                  icon={<FontAwesomeIcon icon={faTrash} />}
                />
              </GammaTooltip>
            </InputRightElement>
          )}
        </InputGroup>
      ))}
      {value.length < maxColors && (
        <Button
          onClick={addValue}
          variant="plain"
          size="sm"
          leftIcon={<FontAwesomeIcon icon={faPlus} />}
        >
          {buttonLabel || <Trans>Add color</Trans>}
        </Button>
      )}
    </>
  )
}
