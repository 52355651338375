import { config } from 'config'

export type ExportFormat = 'pdf' | 'pptx' | 'screenshot'

interface Window {
  format?: ExportFormat
}
declare let window: Window

export const isPuppeteer = config.GAMMA_PUPPETEER_SERVICE
export const isPptExport =
  isPuppeteer && typeof window !== 'undefined' && window.format === 'pptx'
export const isPdfExport =
  isPuppeteer && typeof window !== 'undefined' && window.format === 'pdf'
export const isScreenshot =
  isPuppeteer && typeof window !== 'undefined' && window.format === 'screenshot'
