import { CheckIcon } from '@chakra-ui/icons'
import { Button, ButtonProps, forwardRef } from '@chakra-ui/react'
import { GammaTooltip } from '@gamma-app/ui'

import { ThemeColor } from 'modules/theming/types'
import {
  getThemeColorBackgroundCss,
  isThemeColorDark,
} from 'modules/theming/utils/colors'

export const ThemeColorIcon = forwardRef(
  (
    {
      color,
      name,
      isActive,
      size = 8,
      onClick,
      buttonProps,
    }: {
      color: ThemeColor
      isActive?: boolean
      name?: string
      size?: number
      onClick?: (color: ThemeColor) => void
      buttonProps?: ButtonProps
    },
    ref
  ) => {
    const selectedColor = isThemeColorDark(color) ? 'white' : 'black'
    return (
      <GammaTooltip label={name} placement="top" isDisabled={!name}>
        <Button
          display="inline-block"
          alignSelf="center"
          boxSize={size}
          borderRadius="md"
          border="1px solid"
          borderColor="gray.200"
          color={selectedColor}
          css={getThemeColorBackgroundCss(color)}
          onClick={() => onClick?.(color)}
          variant="unstyled"
          minW={size}
          fontSize="xs"
          transitionProperty="border"
          transitionDuration="normal"
          ref={ref}
          {...buttonProps}
        >
          {isActive && <CheckIcon />}
        </Button>
      </GammaTooltip>
    )
  }
)
ThemeColorIcon.displayName = 'ThemeColorIcon'
