import { ApolloClient, NormalizedCacheObject } from '@apollo/client'
import { CachePersistor } from 'apollo3-cache-persist'
import { memoize } from 'lodash'

import { getStore } from 'modules/redux'

import { apolloCacheDisabled } from '../reducer'
import { isOfflineModeEnabled } from '../utils'
import { IndexedDBStorage } from './IndexeddbStorage'
import { OfflineCache } from './OfflineCache'

export class ApolloOfflineCache implements OfflineCache<any> {
  private readonly key = 'apollo-offline-cache'

  private store = getStore()

  private persistor: CachePersistor<any> | null = null

  private apolloClient: ApolloClient<NormalizedCacheObject> | null = null

  public cacheReady: Promise<void>

  private resolveCacheReady: () => void

  private clientReady: Promise<ApolloClient<NormalizedCacheObject>>

  private resolveClientReady: (
    client: ApolloClient<NormalizedCacheObject>
  ) => void

  private isInitialzed: boolean = false

  private initialzed: Promise<void>

  private initializedResolve: () => void

  constructor() {
    this.clientReady = new Promise<ApolloClient<NormalizedCacheObject>>(
      (resolve) => {
        this.resolveClientReady = resolve
      }
    )

    this.initialzed = new Promise<void>((resolve) => {
      this.initializedResolve = () => {
        this.isInitialzed = true
        resolve()
      }
    })

    this.cacheReady = new Promise<void>((resolve) => {
      this.resolveCacheReady = resolve
    })
  }

  async gc(): Promise<void> {
    if (!this.apolloClient) {
      throw new Error('Cannot gc(), apollo client not set')
    }
    // just gc the apollo cache, this will clean up any dangling references
    // In the future we will rely on an apollo cache with a true ttl mechanism
    this.apolloClient.cache.gc()
  }

  async loadCache(apolloClient: ApolloClient<NormalizedCacheObject>) {
    this.apolloClient = apolloClient
    this.resolveClientReady(apolloClient)

    await this.initialzed
    // try to persist if things are enabled
    if (isOfflineModeEnabled()) {
      const now = performance.now()
      await this.persistor?.restore()
      console.log(
        '[Offline] ApolloOfflineCache loaded cache in ',
        performance.now() - now,
        'ms'
      )
    }

    this.resolveCacheReady()
  }

  async enable(): Promise<void> {
    const apolloClient = await this.clientReady
    this.persistor = new CachePersistor({
      cache: apolloClient.cache,
      storage: new IndexedDBStorage(),
      key: this.key,
      // the apollo-cache-persist types are wrong and break
      // if you pass anything but true
      // @ts-ignore
      serialize: false,
    })
    if (!this.isInitialzed) {
      this.initializedResolve()
    }
  }

  async disable(): Promise<void> {
    console.log('[ApolloOfflineCache] Disabling ApolloOfflineCache')
    if (!this.persistor) {
      if (!this.isInitialzed) {
        this.initializedResolve()
      }
      return
    }
    console.log('[ApolloOfflineCache] Purging ApolloOfflineCache')
    this.persistor.purge()
    this.persistor.pause()

    this.store.dispatch(apolloCacheDisabled())
    this.persistor = null
    if (!this.isInitialzed) {
      this.initializedResolve()
    }
  }

  async debug(): Promise<any> {
    return {
      client: this.apolloClient,
    }
  }
}

export const getApolloOfflineCache = memoize(() => new ApolloOfflineCache())
