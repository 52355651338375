import { t } from '@lingui/macro'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import { CreateThemeInput } from 'modules/api'
import { JsxPrompts } from 'modules/api/generated/jsx-prompt-types'
import { ImportedFile } from 'modules/import/types'
import { RootState } from 'modules/redux'
import {
  FontMap,
  Theme,
  ThemeColor,
  ThemeColorSolid,
} from 'modules/theming/types'
import { EMPTY_THEME } from 'modules/theming/utils/emptyTheme'
import { getUpdatedThemeFonts } from 'modules/theming/utils/fonts'
import { BackgroundType } from 'modules/tiptap_editor/styles/types'

export type ThemeImporterState = {
  theme: Theme
  fileStatus:
    | 'initial' // Pre-upload
    | 'uploading' // Upload has initated
    | 'parsing' // Parsing uploaded file with AI
    | 'review' // Review the theme knobs (user input)
  inputFile: ImportedFile | null
  themeSuggestions: JsxPrompts['ImportPptTheme']['output'] | null
}

const initialState: ThemeImporterState = {
  fileStatus: 'initial',
  inputFile: null,
  theme: EMPTY_THEME,
  themeSuggestions: null,
}

const ThemeImporterSlice = createSlice({
  name: 'ThemeImporter',
  initialState,
  reducers: {
    resetThemeImporterState: () => initialState,
    startUploadingPpt(state) {
      state.fileStatus = 'uploading'
    },
    finishUploadingPpt(
      state: ThemeImporterState,
      action: PayloadAction<{
        inputFile: ImportedFile
      }>
    ) {
      state.inputFile = action.payload.inputFile
      // Advance to the next step: parsing
      state.fileStatus = 'parsing'
    },
    finishParsingPpt(
      state: ThemeImporterState,
      action: PayloadAction<{
        themeSuggestions: JsxPrompts['ImportPptTheme']['output']
        fontsMap: FontMap
      }>
    ) {
      state.fileStatus = 'review'
      const { themeSuggestions } = action.payload

      // Set theme suggestions for knobs
      state.themeSuggestions = action.payload.themeSuggestions

      // Set theme to have the suggested values
      const themeToSet: Theme = {
        ...state.theme,
        name: t({
          message: `Imported theme`,
          comment: 'The name of a visual theme imported by the user',
        }),
        headingFont: themeSuggestions.headingFont.suggested,
        headingFontWeight: themeSuggestions.headingFontWeight,
        bodyFont: themeSuggestions.bodyFont.suggested,
        bodyFontWeight: themeSuggestions.bodyFontWeight,
        config: {
          ...state.theme?.config,
          primaryColor: {
            color: themeSuggestions.accentColors[0],
            type: 'solid',
          },
          headingColor: {
            color: themeSuggestions.headingColors[0],
            type: 'solid',
          },
          bodyColor: {
            color: themeSuggestions.bodyColors[0],
            type: 'solid',
          },
          cardColor: {
            color: themeSuggestions.cardColors[0],
            type: 'solid',
          },
          container: {
            isDark: themeSuggestions.container.isDark,
          },
          disableReadabilityAdjustment: false,
          background: {
            type: BackgroundType.COLOR,
            color: {
              name: themeSuggestions.backgroundColors[0],
              hex: themeSuggestions.backgroundColors[0],
            },
          },
          ...(themeSuggestions.accentColors.length > 1
            ? {
                secondaryColors: themeSuggestions.accentColors
                  .slice(1)
                  .map((color) => ({
                    color,
                    type: 'solid',
                  })),
              }
            : {}),
        },
        fonts: getUpdatedThemeFonts({
          fontsMap: action.payload.fontsMap,
          headingFontId: themeSuggestions.headingFont.suggested,
          bodyFontId: themeSuggestions.bodyFont.suggested,
        }),
      }
      state.theme = themeToSet
    },
    updateThemeName(
      state: ThemeImporterState,
      action: PayloadAction<{ name: string }>
    ) {
      state.theme.name = action.payload.name
    },
    updateCardColor(
      state: ThemeImporterState,
      action: PayloadAction<{ color: ThemeColorSolid }>
    ) {
      state.theme.config.cardColor = action.payload.color
    },
    updatePrimaryAccentColor(
      state: ThemeImporterState,
      action: PayloadAction<{ color: ThemeColorSolid }>
    ) {
      state.theme.config.primaryColor = action.payload.color
    },
    updateHeadingFont(
      state: ThemeImporterState,
      action: PayloadAction<{
        font: string
        fontsMap: FontMap
      }>
    ) {
      const { font, fontsMap } = action.payload
      state.theme.headingFont = font
      state.theme.fonts = getUpdatedThemeFonts({
        fontsMap: fontsMap,
        headingFontId: font,
        bodyFontId: state.theme.bodyFont,
      })
    },
    updateBodyFont(
      state: ThemeImporterState,
      action: PayloadAction<{ font: string; fontsMap: FontMap }>
    ) {
      const { fontsMap, font } = action.payload
      state.theme.bodyFont = font
      state.theme.fonts = getUpdatedThemeFonts({
        fontsMap: fontsMap,
        bodyFontId: font,
        headingFontId: state.theme.headingFont,
      })
    },
    updateHeadingFontWeight(
      state: ThemeImporterState,
      action: PayloadAction<{ weight: Theme['headingFontWeight'] }>
    ) {
      state.theme.headingFontWeight = action.payload.weight
    },
    updateBodyFontWeight(
      state: ThemeImporterState,
      action: PayloadAction<{ weight: Theme['bodyFontWeight'] }>
    ) {
      state.theme.bodyFontWeight = action.payload.weight
    },
    updateBodyColor(
      state: ThemeImporterState,
      action: PayloadAction<{ color: ThemeColorSolid }>
    ) {
      state.theme.config.bodyColor = action.payload.color
    },
    updateHeadingColor(
      state: ThemeImporterState,
      action: PayloadAction<{ color: ThemeColor }>
    ) {
      state.theme.config.headingColor = action.payload.color
    },
  },
})

export const {
  resetThemeImporterState,
  finishUploadingPpt,
  startUploadingPpt,
  finishParsingPpt,
  updateThemeName,
  updateCardColor,
  updatePrimaryAccentColor,
  updateHeadingFont,
  updateBodyFont,
  updateHeadingFontWeight,
  updateBodyFontWeight,
  updateBodyColor,
  updateHeadingColor,
} = ThemeImporterSlice.actions

type ThemeImporterSliceState = Pick<RootState, 'ThemeImporter'>

// Selectors
export const selectThemeImporterTheme = (state: ThemeImporterSliceState) =>
  state.ThemeImporter.theme

export const selectThemeImporterCurrentStep = (
  state: ThemeImporterSliceState
): 'initialStep' | 'uploadStep' | 'reviewStep' => {
  if (state.ThemeImporter.fileStatus === 'initial') {
    return 'initialStep'
  }
  if (
    state.ThemeImporter.fileStatus === 'uploading' ||
    state.ThemeImporter.fileStatus === 'parsing'
  ) {
    return 'uploadStep'
  }
  return 'reviewStep'
}

export const selectThemeImporterInputFile = (state: ThemeImporterSliceState) =>
  state.ThemeImporter.inputFile

export const selectThemeImporterFileStatus = (state: ThemeImporterSliceState) =>
  state.ThemeImporter.fileStatus

export const selectSuggestedAccentColors = (
  state: ThemeImporterSliceState
): ThemeColorSolid[] => {
  const accentColors = state.ThemeImporter.themeSuggestions?.accentColors
  return accentColors
    ? accentColors.map((color) => ({
        color,
        type: 'solid',
      }))
    : []
}

export const selectFontSuggestionsAndOriginals = (
  state: ThemeImporterSliceState
) => {
  return {
    suggestedBodyFont: state.ThemeImporter.themeSuggestions?.bodyFont,
    suggestedHeadingFont: state.ThemeImporter.themeSuggestions?.headingFont,
  }
}

export const selectThemeImporterCreateThemeInput =
  (workspaceId: string) =>
  (state: ThemeImporterSliceState): CreateThemeInput => {
    return {
      workspaceId,
      name: state.ThemeImporter.theme.name,
      headingFont: state.ThemeImporter.theme.headingFont,
      headingFontWeight: state.ThemeImporter.theme.headingFontWeight,
      bodyFont: state.ThemeImporter.theme.bodyFont,
      bodyFontWeight: state.ThemeImporter.theme.bodyFontWeight,
      config: state.ThemeImporter.theme.config,
      priority: state.ThemeImporter.theme.priority,
    }
  }

export const ThemeImporterReducer = ThemeImporterSlice.reducer
