import { createSlice } from '@reduxjs/toolkit'
import { JSONContent } from '@tiptap/core'

import { DocGeneration } from 'modules/api'
import { PDFThumbnail } from 'modules/import/types'
import { RootState } from 'modules/redux'
import { Theme } from 'modules/theming'
import { selectAllThemes } from 'modules/theming/themePicker/reducer'

import { PptImportDocGenerateSettings } from './types'
import { originalThumbnailCard } from './utils'

type ImportPptStatus = 'importing' | 'done'

export type ImportPPTState = {
  interactionId: string

  docGeneration: DocGeneration | null

  file: {
    filename: string
    htmlUrl: string
    thumbnails: PDFThumbnail[]
  } | null
  slides: ImportSlideState[]

  themeId: string | null
}

export type ImportSlideState = {
  status: 'importing' | 'generating' | 'done'
  index: number
  thumbnail: PDFThumbnail

  startTime: number

  rating: 'poor' | 'good' | undefined
  deleted: boolean
  useOriginalImage: boolean

  // step 1
  parsedHtml?: string
  parsedHtmlTime?: number
  // step 3
  aiHtml?: string
  aiHtmlTime?: number
  // step 3
  cardContent?: JSONContent
  cardContentTime?: number
}

const initialState: ImportPPTState = {
  // make this always a string since the initial `loadDocGeneration` should always
  // populate the interactionId, even if it's not present on the docGeneration.draftInput
  interactionId: '',
  docGeneration: null,
  file: null,
  slides: [],
  themeId: null,
}

const ImportPPTSlice = createSlice({
  name: 'ImportPPT',
  initialState,
  reducers: {
    resetImportState: () => initialState,
    loadDocGeneration(
      state,
      action: {
        payload: {
          docGeneration: DocGeneration
        }
      }
    ) {
      const { docGeneration } = action.payload
      if (!docGeneration.draftInput) {
        throw new Error(
          'Cannot load DocGeneration for ImportPPT with no draftInput'
        )
      }

      const { filename, htmlUrl, thumbnails } = docGeneration.draftInput
        .settings as PptImportDocGenerateSettings

      // right now we just generate a new interactionId when the doc generation is loaded
      // since each load of a doc generation kicks off a new AI import
      state.docGeneration = docGeneration
      state.file = { filename, htmlUrl, thumbnails }
      state.slides = thumbnails.map((thumbnail, index) => ({
        status: 'importing',
        useOriginalImage: false,
        rating: undefined,
        deleted: false,
        index,
        thumbnail,
        startTime: performance.now(),
      }))
    },

    updateSlideState(
      state,
      action: {
        payload: Partial<ImportSlideState> & { index: number }
      }
    ) {
      const { index, ...rest } = action.payload
      Object.assign(state.slides[index], rest)
    },

    setPptPreviewThemeId(
      state,
      action: {
        payload: { themeId: string | null }
      }
    ) {
      state.themeId = action.payload.themeId
    },

    updateImportPptInteractionId(
      state,
      action: {
        payload: {
          interactionId: string
        }
      }
    ) {
      state.interactionId = action.payload.interactionId
    },
  },
})

export const {
  resetImportState,
  updateImportPptInteractionId,
  updateSlideState,
  loadDocGeneration,
  setPptPreviewThemeId,
} = ImportPPTSlice.actions

export const ImportPPTReducer = ImportPPTSlice.reducer

export const selectImportPptSlides = (state: RootState) =>
  state.ImportPPT.slides

export const selectImportSlideState = (num: number) => (state: RootState) =>
  state.ImportPPT.slides[num - 1]

const selectTotalSlides = (state: RootState) =>
  state.ImportPPT.file?.thumbnails.length ?? 0

const selectNumImportedSlides = (state: RootState) =>
  state.ImportPPT.slides.filter((slide) => slide.status === 'done').length

export const selectPptPreviewThemeId = (state: RootState) =>
  state.ImportPPT.themeId

export const selectPptPreviewTheme = (state: RootState): Theme | undefined => {
  if (!state.ImportPPT.themeId) {
    return
  }

  return selectAllThemes(state).find(
    (theme) => theme.id === state.ImportPPT.themeId
  )
}

export const selectImportStatus = (state: RootState) => {
  const allSlidesDone = state.ImportPPT.slides.every(
    (slide) => slide.status === 'done'
  )

  const totalSlides = selectTotalSlides(state)
  const numImportedSlides = selectNumImportedSlides(state)
  const hasFile = state.ImportPPT.file !== null

  const status: ImportPptStatus =
    allSlidesDone && hasFile && totalSlides === numImportedSlides
      ? 'done'
      : 'importing'

  return {
    status,
    totalSlides,
    numImportedSlides,
  }
}

export const selectDocGenerationDraftInputId = (state: RootState) => {
  return state.ImportPPT.docGeneration?.draftInput?.id
}

export const selectDocContentJSON = (state: RootState) => {
  return state.ImportPPT.slides
    .filter((a) => !a.deleted)
    .map((slide) => {
      if (slide.useOriginalImage) {
        return originalThumbnailCard(slide.thumbnail)
      }

      return slide.cardContent!
    })
}

export const selectImportPptHtmlUrl = (state: RootState): string | undefined =>
  state.ImportPPT.file?.htmlUrl

export const selectImportPPTState = (state: RootState) => state.ImportPPT

export const selectImportPptInteractionId = (state: RootState): string =>
  state.ImportPPT.interactionId

export const selectImportPptDraftInputId = (state: RootState): string | null =>
  state.ImportPPT.docGeneration?.draftInput?.id ?? null
