import { createSlice } from '@reduxjs/toolkit'

import { CreateThemeInput, Theme } from 'modules/api'
import { JsxPrompts } from 'modules/api/generated/jsx-prompt-types'
import { ImportedFile } from 'modules/import/types'
import { RootState } from 'modules/redux'

import { FindReadableColorInput } from './utils'

type StartEndTimes = {
  start: number | null
  end: number | null
}

type BaseDebugStep = {
  status: 'loading' | 'error' | 'success'
  latency: StartEndTimes
  error?: string
}

export type DebugStepPayloads = {
  DetermineSlideFonts: {
    name: 'DetermineSlideFonts'
    input: JsxPrompts['DetermineSlideFonts']['variables']
    output: JsxPrompts['DetermineSlideFonts']['output']
  }

  SubstituteGoogleFonts: {
    name: 'SubstituteGoogleFonts'
    input: JsxPrompts['SubstituteGoogleFonts']['variables']
    output: JsxPrompts['SubstituteGoogleFonts']['output']
  }

  ExtractThemeColors: {
    name: 'ExtractThemeColors'
    input: JsxPrompts['ExtractThemeColors']['variables']
    output: JsxPrompts['ExtractThemeColors']['output']
  }

  findReadableHeadingColor: {
    name: 'findReadableHeadingColor'
    input: FindReadableColorInput
    output: string
  }

  findReadableAccentColor: {
    name: 'findReadableAccentColor'
    input: FindReadableColorInput
    output: string
  }

  findReadableBodyColor: {
    name: 'findReadableBodyColor'
    input: FindReadableColorInput
    output: string
  }

  adjustBackgroundColors: {
    name: 'adjustBackgroundColors'
    input: {
      backgroundColor: string
    }
    output: {
      cardBackgroundColor: string
      backgroundColor: string
    }
  }

  createTheme: {
    name: 'createTheme'
    input: CreateThemeInput
    output: CreateThemeInput
  }
}

export type DebugStepFunctionName = keyof DebugStepPayloads
export type DebugStepName = DebugStepPayloads[keyof DebugStepPayloads]['name']

export type DebugStep<K extends DebugStepFunctionName = DebugStepFunctionName> =
  DebugStepPayloads[K] & BaseDebugStep

export type ThemeImportProcessState = {
  inputFile: ImportedFile | null
  theme: Theme | null
  uploading: boolean
  debugSteps: DebugStep[]
  latency: {
    importFile: StartEndTimes
    createTheme: StartEndTimes
    parseHtml: StartEndTimes
  }
}

const initialStartEndTimes: StartEndTimes = {
  start: null,
  end: null,
}

const initialState: ThemeImportProcessState = {
  debugSteps: [],
  latency: {
    importFile: initialStartEndTimes,
    createTheme: initialStartEndTimes,
    parseHtml: initialStartEndTimes,
  },
  inputFile: null,
  theme: null,
  uploading: false,
}

const ThemeImportDebuggerSlice = createSlice({
  name: 'ThemeImportDebugger',
  initialState,
  reducers: {
    resetThemeImportState: () => initialState,

    uploadPptComplete(
      state,
      action: {
        payload: {
          inputFile: ImportedFile
        }
      }
    ) {
      state.uploading = false
      state.latency.importFile.end = performance.now()
      state.inputFile = action.payload.inputFile
    },

    uploadPptStart(state) {
      state.uploading = true
      state.latency.importFile.start = performance.now()
    },

    htmlParseStart(state) {
      state.latency.parseHtml.start = performance.now()
    },

    htmlParseEnd(state) {
      state.latency.parseHtml.end = performance.now()
    },

    createThemeStart(
      state,
      action: {
        payload: {
          file: ImportedFile
        }
      }
    ) {
      const { file } = action.payload
      state.latency.createTheme.start = performance.now()
      state.inputFile = file
    },

    createThemeEnd(
      state,
      action: {
        payload: {
          theme: Theme
        }
      }
    ) {
      const { theme } = action.payload
      state.theme = theme
      state.latency.createTheme.end = performance.now()
    },

    debugStepStart<K extends DebugStepFunctionName>(
      state,
      action: {
        payload: {
          name: K
          input: DebugStepPayloads[K]['input']
        }
      }
    ) {
      const { name, input } = action.payload

      state.debugSteps.push({
        name,
        input,
        status: 'loading',
        latency: {
          start: performance.now(),
          end: null,
        },
        result: undefined,
        error: undefined,
      })
    },

    debugStepEnd<K extends DebugStepFunctionName>(
      state,
      action: {
        payload: {
          name: K
          output?: DebugStepPayloads[K]['output']
          error?: string
        }
      }
    ) {
      const { name, error, output } = action.payload

      const debugStep = state.debugSteps.find(
        (d: DebugStep<K>) => d.name === name
      )

      if (!debugStep) {
        console.error('Debug step not found')
        return
      }

      if (debugStep.status !== 'loading') {
        console.error('Debug step not loading')
        return
      }

      debugStep.latency.end = performance.now()

      if (error) {
        debugStep.status = 'error'
        debugStep.error = error
        return
      }

      if (!output) {
        debugStep.status = 'error'
        debugStep.error = 'Result is undefined'
        return
      }
      debugStep.output = output ?? debugStep.output
      debugStep.status = 'success'
    },
  },
})

export const {
  resetThemeImportState,
  uploadPptStart,
  uploadPptComplete,
  createThemeStart,
  createThemeEnd,
  debugStepEnd,
  debugStepStart,
  htmlParseStart,
  htmlParseEnd,
} = ThemeImportDebuggerSlice.actions

export const ThemeImportDebuggerReducer = ThemeImportDebuggerSlice.reducer

export const selectThemeImportState = (state: RootState) =>
  state.ThemeImportDebugger
