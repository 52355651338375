import jsCookie from 'js-cookie'
import { customAlphabet } from 'nanoid'
import tinycolor from 'tinycolor2'

import { config } from 'config'
import { JsonPatch } from 'modules/api'
import { generateColor, generateName } from 'utils/generators'

import { UserSetting, UserSettings } from './context/UserContext'

const generateVisitorId = customAlphabet(
  '0123456789abcdefghijklmnopqrstuvwxyz',
  15
)

export const isPersonalEmail = (email: string) => {
  return (
    email.endsWith('gmail.com') ||
    email.endsWith('hotmail.com') ||
    email.endsWith('.edu')
  )
}

const VISITOR_ID_COOKIE = 'gamma_visitor_id'
const VISITOR_COOKIE_EXPIRATION_DAYS = 10 * 365 // 10 years

export const LOGGED_IN_COOKIE_NAME = 'gamma_logged_in'
const TEN_YEARS_IN_MS = 10 * 365 * 86400 * 1000 // session expires in 10 years

export const LOGGED_IN_COOKIE_MAX_AGE = TEN_YEARS_IN_MS

/**
 * Ensure there is a user id set in the cookie,
 * creating a new one if necessary, and return it
 */
export const ensureVisitorIdCookie = (): string => {
  const id = jsCookie.get(VISITOR_ID_COOKIE) || undefined
  if (id) return id
  const newId = generateVisitorId()
  jsCookie.set(VISITOR_ID_COOKIE, newId, {
    domain: config.VISITOR_ID_COOKIE_DOMAIN,
    expires: VISITOR_COOKIE_EXPIRATION_DAYS,
    // this cookie needs to be sameSite=none in order for all domains to see it
    // (including custom versions or custom domains)
    sameSite: 'none',
    // it has to be secure for sameSite=none to stick in Chrome
    secure: true,
  })
  return newId
}

export const getAnonymousUser = (id: string) => {
  return {
    id,
    displayName: id ? generateName(id) : '',
  }
}

export const getHasLoggedInCookie = () => {
  return Boolean(jsCookie.get(LOGGED_IN_COOKIE_NAME))
}

export const getColorObject = (id?: string) => {
  const colorValue = id ? generateColor(id) : '#cccccc'
  return {
    value: colorValue,
    isDark: tinycolor(colorValue).isDark(),
  }
}

export const generateSettingsUpdatePatch = (settings: {
  set?: Partial<UserSettings>
  remove?: UserSetting[]
}): JsonPatch[] => {
  const replacements: JsonPatch[] = Object.keys(settings.set || {}).map(
    (key) => {
      return {
        op: 'replace',
        path: '/' + key,
        value: settings.set?.[key],
      } as JsonPatch
    }
  )
  const removals: JsonPatch[] = (settings.remove || []).map((key) => {
    return {
      op: 'remove',
      path: '/' + key,
    } as JsonPatch
  })
  return [...replacements, ...removals]
}
