import { CSSObject } from '@emotion/react'
import { User } from '@gammatech/schema'
import React from 'react'

import { Font, ThemeFont } from 'modules/api'
import { ImageAttrs } from 'modules/media/types/Image'
import { MaybeHex } from 'modules/theming/components/ColorPickerMenu/types'
import { ImageShape } from 'modules/tiptap_editor/extensions/SmartLayout/options/types'
import {
  BackgroundOptions,
  ContainerOptions,
} from 'modules/tiptap_editor/styles/types'

import { LinearGradient } from './components/LinearGradientPicker/types'

export type ThemeType = 'custom' | 'standard' | 'archived'

export type Theme = {
  id: string
  workspaceId?: string
  name: string
  archived: boolean
  createdBy?: Partial<
    Pick<User, 'id' | 'displayName' | 'email' | 'profileImageUrl'>
  >
  createdTime?: string
  updatedTime?: string
  priority: number
  // Styles
  headingFont?: string
  headingFontWeight?: number
  bodyFont?: string
  bodyFontWeight?: number
  accentColor?: string // [v1 - deprecated]. Prefer config.primaryColor [v3] if we have it.
  logoUrl?: string // Deprecated. Prefer config.logoImage if we have it
  config: Partial<ThemeKnobsV3> & {
    // Deck background
    background?: BackgroundOptions // [existing] The deck background. Only visible in doc mode.

    // Color
    primaryColor?: ThemeColor // [v3] The primary accent color of the theme. Prefer over legacy theme.accentColor
    secondaryColors?: ThemeColor[] // [v3] The secondary accent colors of the theme. Prefer over legacy config.secondaryAccentColors
    headingColor?: ThemeColor | string // [v1]
    bodyColor?: ThemeColorSolid | string // [v1]
    linkColor?: ThemeColorSolid // [v3] The color of links. Pre-V3 we used accent color.
    buttonColor?: ThemeColor // [v3] The color of buttons. Pre-V3 we used accent color.
    cardColor?: ThemeColorSolid // [v3] The color of the card. In V2 we used cardBackground. In V1 we used container.isDark
    cardBorderColor?: ThemeColorSolid // [v3] The color of the card border.
    shapeColors?: ThemeColorSolid[] // [v3] The color of smart layouts, if shapeColorScheme == custom. In V2 we used accent color(s).
    disableReadabilityAdjustment?: boolean // [v2] Disable the automatic adjustment of text color to improve readability

    // Fonts
    fontSize?: number // [v2] A multiplier on the font size, defaults to 1
    headingFontSize?: number // [v3] A multiplier on the heading font size, defaults to 1
    letterSpacingBody?: number // [v3] Letter spacing for body text, defaults to 0
    letterSpacingHeading?: number // [v3] Letter spacing for headings, defaults to 0
    lineHeightBody?: number // [v3] Line height for body text
    lineHeightHeading?: number // [v3] Line height for headings
    headingTransform?: 'uppercase' | 'none' // [v3] Transform for headings, defaults to none

    // Images
    accentBackgrounds?: BackgroundOptions[] // [v2] A set of splashy backgrounds for card layouts. New cards will cycle through these, if provided
    stylePrompt?: string // AI Image Generation Style Prompt

    // Logo
    logoImage?: ImageAttrs

    // Deprecated properties. We dont write to these anymore, but still read from them
    contentStyles?: Record<string, any> // [deprecating] Just used for a few line heights and other effects
    container?: ContainerOptions // [deprecated] isDark and width are still needed in new world. Maybe move these out to top level props?
    themeBase?: string // [deprecated]
    cardBackground?: BackgroundOptions // deprecated] The default background color for the card body.
    secondaryAccentColors?: MaybeHex[] // [deprecated] Optional secondary colors in addition to the accent color. An ordered list.
    headingGradient?: LinearGradient // [deprecated]
    accentGradient?: LinearGradient // [deprecated]
    keywords?: {
      // Currently only used for AI theme suggestions
      color: string[]
      tone: string[]
    }
  }
  fonts?: ThemeFont[]
  docCount?: number
}

export type ThemeKnobsV3 = {
  cardTransparency: 'default' | 'frosted' | 'faded'
  roundness: 'sm' | 'md' | 'lg' | 'xl'
  buttonRoundness: 'sm' | 'md' | 'lg' | 'xl'
  cardShadow: 'none' | 'default' | 'soft3d' | 'hard'
  cardBorder: 'none' | 'sm' | 'md' | 'lg'
  accentCut:
    | 'default'
    | 'diagonal'
    | 'round'
    | 'roundInverse'
    | 'wiggle'
    | 'fade'
  shapeFill: 'solid' | 'semi' | 'none'
  shapeShadow: 'none' | 'soft' | 'hard' | 'soft3d'
  shapeBorder: 'none' | 'sm' | 'md' | 'lg'
  shapeColorScheme: 'default' | 'accent' | 'custom' // future: add option to lerp your gradient
}

export type ThemeBase = {
  key: string
  name: () => string
  description?: () => string
  cardSx: ThemeCSSObject
  cardFullBleedSx?: ThemeCSSObject
  boxSx?: ThemeCSSObject //
  imageSx?: ThemeCSSObject // Images, media placeholder
  clickableSx?: ThemeCSSObject &
    Partial<{
      '&.button': ThemeCSSObject
    }> // Nested cards, embeds/videos, buttons

  // For smart layouts and diagrams
  outsideTextSx?: CSSObject // Font effects for text around shapes - like timeline labels, image captions, pyramid outside text
  boxBackgroundSx?: CSSObject // Background for boxes like bullet markers, staircase steps that can have content over them
  shapeBackgroundSx?: CSSObject // Background for SVG shapes like pyramid steps, arrows, circle stats that can have content over them
  boxFillSx?: CSSObject // Foreground for filled boxes like bar stats
  shapeFillSx?: CSSObject // Foreground for filled SVG shapes like circle stats
  lineSx?: CSSObject // For lines like on timeline, diagram connectors,
  hasTransparentShapes?: boolean // If this is true, don't adjust the color of content inside smart layouts when the color is overridden
  smartLayoutImageSx?: CSSObject // Styles applied to smart layout images
  smartLayoutImageShape?: ImageShape // The default shape of images in smart layouts

  tableSx?: CSSObject // Styles applied to tables
  SVGDefs?: React.FC<{
    theme: Theme
  }>
  ornaments?: ThemeOrnament[]
  imageMasks?: any[]
  getCSSVars: (props: {
    cardColor: string
    accentColor: string
    accentPalette: string[]
    config: Theme['config'] & ThemeKnobsV3
    theme: Theme
  }) => Record<string, string | number | undefined> & {
    '--line-thickness': string
  }
  tldrawStyles?: {
    fill: 'semi' | 'solid' | 'none'
    strokeWidth: 'none' | 'sm' | 'md' | 'lg'
  }
  // When we deprecate theme bases, old themes will still need the settings
  // This maps old theme bases to new config options
  knobDefaults?: Partial<ThemeKnobsV3>
}

type ThemeCSSObject = Partial<
  Pick<
    CSSObject,
    | 'borderRadius'
    | 'border'
    | 'boxShadow'
    | 'backgroundColor'
    | '@media print'
    | 'backdropFilter' // should this be done via card background instead?
    | '_hover'
  >
>

export type ThemeOrnament = {
  key: string
  image: StaticImageData
}

export type FontMap = {
  [fontId: string]: Font
}

export type FontPickerFonts = {
  globalFonts: Font[]
  workspaceFonts: Font[]
}

export type ThemeColorSolid = {
  type: 'solid'
  color: string
}

export type ThemeColorGradient = {
  type: 'linear-gradient'
  stops: [GradientStop, GradientStop, ...GradientStop[]]
  fallbackColor?: string // Fallback when gradient is not supported
  angle: number
}

export type GradientStop = {
  color: string
  position: number
}

export type ThemeColor = ThemeColorSolid | ThemeColorGradient
