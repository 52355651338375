import { useCallback, useEffect, useMemo } from 'react'

import { useGetCreditsLazyQuery } from 'modules/api'
import { useFeatureFlag } from 'modules/featureFlags'
import { useMonetizationContext } from 'modules/monetization/context'
import { useCanUseProductFeature } from 'modules/monetization/hooks/productFeatures'
import { useAppDispatch, useAppSelector } from 'modules/redux'
import { SegmentEvents } from 'modules/segment/segmentEvents'
import { selectUserState } from 'modules/user/reducer'
import { useSSRMounted } from 'utils/hooks/useSSRMounted'

import {
  DEFAULT_CREDIT_COSTS,
  DEFAULT_CREDIT_INTERACTION,
  LOW_AI_INTERACTION_THRESHOLD,
} from './constants'
import { selectAvailableCredits, setCredits } from './reducer'
import { CreditCostTypes } from './types'
import { checkCredits, getCreditThresholdStatus } from './utils'

const ONE_MIN_IN_MS = 60 * 1000
const REFRESH_INTERVAL = ONE_MIN_IN_MS * 5

// Since we dont have a subscription to observe credits yet,
// create a hook that will refresh the credits every 60 seconds
export const useRefreshCredits = () => {
  const { user, currentWorkspaceId: workspaceId } =
    useAppSelector(selectUserState)

  const [getCredits] = useGetCreditsLazyQuery({
    nextFetchPolicy: 'network-only',
  })
  const dispatch = useAppDispatch()
  const ssrReady = useSSRMounted()

  // Only sync credits if the user is logged in and the feature flag is enabled
  const shouldSyncCredits = ssrReady && Boolean(user)

  useEffect(() => {
    if (!shouldSyncCredits || !workspaceId) return

    const cb = async () => {
      const result = await getCredits({
        variables: { workspaceId },
      })
      const response = result.data?.credits
      if (!response) {
        return
      }

      dispatch(setCredits({ availableCredits: response.credits }))
    }
    cb()

    const interval = setInterval(cb, REFRESH_INTERVAL)

    return () => clearInterval(interval)
  }, [dispatch, getCredits, workspaceId, shouldSyncCredits])
}

export const useAllowOrUpsell = (
  onAllowed: ((...args: any[]) => void) | undefined,
  type: CreditCostTypes,
  segmentEvent: SegmentEvents
): (() => boolean) => {
  const { openUpsellModal } = useMonetizationContext()

  return useCallback(
    (...args) => {
      if (checkCredits(type)) {
        onAllowed?.(...args)
        return true
      } else {
        openUpsellModal({
          insufficientCreditsWarning: true,
          segmentEvent: segmentEvent,
        })
        return false
      }
    },
    [onAllowed, openUpsellModal, segmentEvent, type]
  )
}

export const useCreditStatus = () => {
  const availableCredits = useAppSelector(selectAvailableCredits)
  const creditCosts = useFeatureFlag('aiDebits')
  const hasUnlimitedCredits = useCanUseProductFeature('unlimited_credits')

  const lowCreditStatus = useMemo(() => {
    if (hasUnlimitedCredits) {
      // Don't show any low credit status
      return null
    }

    return getCreditThresholdStatus(
      availableCredits,
      creditCosts[DEFAULT_CREDIT_INTERACTION] ||
        DEFAULT_CREDIT_COSTS[DEFAULT_CREDIT_INTERACTION],
      LOW_AI_INTERACTION_THRESHOLD
    )
  }, [hasUnlimitedCredits, availableCredits, creditCosts])
  const isCreditsReady = availableCredits !== null
  const hasEnoughCreditsToGenerateDeck =
    hasUnlimitedCredits ||
    (isCreditsReady &&
      availableCredits >=
        (creditCosts.wizardCreation ?? DEFAULT_CREDIT_COSTS.wizardCreation))

  const hasEnoughCreditsToChat =
    hasUnlimitedCredits ||
    (isCreditsReady &&
      availableCredits >=
        (creditCosts.chatSuggestion ?? DEFAULT_CREDIT_COSTS.chatSuggestion))

  const hasEnoughCreditsToGenerateImage =
    hasUnlimitedCredits ||
    (isCreditsReady &&
      availableCredits >=
        (creditCosts.generateImage ?? DEFAULT_CREDIT_COSTS.generateImage))

  return {
    lowCreditStatus,
    hasEnoughCreditsToGenerateDeck,
    hasEnoughCreditsToGenerateImage,
    hasEnoughCreditsToChat,
    credits: availableCredits,
    isCreditsReady,
  }
}
