import {
  LDClient,
  LDFlagChangeset,
  LDFlagSet,
  LDMultiKindContext,
} from 'launchdarkly-js-client-sdk'
import { useEffect, useState } from 'react'

import { config } from 'config'
import { Organization } from 'modules/api'
import { ConnectionEventEmitter } from 'modules/connection/ConnectionEventEmitter'
import { getProductForWorkspace } from 'modules/monetization/utils'
import { Deferred } from 'utils/deferred'
import { shouldUsePublishedVersion } from 'utils/publishing'
import { localStore } from 'utils/storage'

import { FeatureFlags, FEATURE_FLAG_DEFAULTS } from './flags'
import { getFlattenedFlagsFromChangeset } from './utils'

type UnsubscribeFn = () => void

type OnUpdateFn<K = any> = (value: K) => void

export type FlagValue = FeatureFlags[keyof FeatureFlags]

type FlagMap = FeatureFlags

export const USER_TESTING_OVERRIDES: Partial<FlagMap> =
  config.IS_USER_TESTING_SESSION
    ? {
        cardLayoutsEnabled: true,
      }
    : {}

export type FlagExplanation = {
  key: string
  defaultValue: FlagValue
  value: FlagValue | undefined
  source: 'default' | 'flag' | 'override'
  overrideValue: FlagValue | undefined
  type: 'string' | 'boolean' | 'number'
}

export interface FeatureFlagProvider<K extends keyof FlagMap = keyof FlagMap> {
  initialize(ldClient: LDClient, initialFlags: LDFlagSet): void

  initializePromise: Deferred<boolean>

  hasInitialized: boolean

  get(flag: K): FlagMap[K]

  overrideFlag(flag: K, val: FlagMap[K]): void

  removeOverride(flag: K): void

  all(): FlagMap

  subscribe(flag: K, fn: OnUpdateFn<FlagMap[K]>): UnsubscribeFn

  onChange(fn: () => void): UnsubscribeFn

  explain(): FlagExplanation[]
}

interface Store<K> {
  set(key: string, val: K): void

  get(key: string): K | undefined

  delete(key: string)
}

class LocalStorageStore<K> implements Store<K> {
  set(key: string, val: K): void {
    localStore.setItem(key, JSON.stringify(val))
  }

  get(key: string): K | undefined {
    if (typeof window === 'undefined') return

    const raw = localStore.getItem(key)
    if (!raw) return undefined
    try {
      return JSON.parse(raw) as K
    } catch (e) {
      return
    }
  }

  delete(key: string) {
    localStore.removeItem(key)
  }
}

export class FeatureFlagProvider<K extends keyof FlagMap = keyof FlagMap>
  implements FeatureFlagProvider<K>
{
  private readonly CACHE_KEY = 'gammaFeatureFlagCache'

  private readonly OVERRIDES_KEY = 'gammaFeatureFlagOverrides'

  initializePromise: Deferred<boolean> = new Deferred()

  hasInitialized: boolean = false

  flags: Partial<FlagMap> = {}

  overrides: Partial<FlagMap> = {}

  defaults: FlagMap

  ldClient: LDClient

  private updateFns: Partial<Record<K, OnUpdateFn[]>> = {}

  private onChangeFns: (() => void)[] = []

  private store: Store<Partial<FlagMap>>

  constructor(
    defaults: FlagMap,
    options: { store: Store<Partial<FlagMap>> } = {
      store: new LocalStorageStore(),
    }
  ) {
    this.defaults = defaults
    this.store = options.store
    // Initialize flags from cache
    this.flags = this.store.get(this.CACHE_KEY) || {}
    // Set any overrides immediately as they take precedence anyway
    this.overrides = this.store.get(this.OVERRIDES_KEY) || {}
  }

  onChange(fn: () => void): UnsubscribeFn {
    this.onChangeFns.push(fn)
    return () => {
      try {
        const ind = this.onChangeFns.indexOf(fn)
        if (ind > -1) {
          this.onChangeFns.splice(ind, 1)
        }
      } catch (e) {
        // swallow
      }
    }
  }

  explain(): FlagExplanation[] {
    return Object.entries(this.defaults).map(([key, defaultValue]) => {
      const type: 'string' | 'boolean' | 'number' = typeof defaultValue as any
      const value = this.flags[key]
      const overrideValue = this.overrides[key]
      const source =
        typeof overrideValue !== 'undefined'
          ? 'override'
          : typeof value !== 'undefined'
          ? 'flag'
          : 'default'

      return {
        key,
        type,
        defaultValue,
        value,
        overrideValue,
        source,
      }
    })
  }

  subscribe(flag: K, fn: OnUpdateFn<FlagMap[K]>): UnsubscribeFn {
    this.updateFns[flag] = this.updateFns[flag] || []
    this.updateFns[flag]!.push(fn)
    return () => {
      try {
        const ind = this.updateFns[flag]!.indexOf(fn)
        if (ind > -1) {
          this.updateFns[flag]!.splice(ind, 1)
        }
      } catch (e) {
        // swallow
      }
    }
  }

  initialize(ldClient: LDClient, initialFlags: LDFlagSet): void {
    if (this.hasInitialized) {
      console.warn(
        '[FeatureFlagProvider].initialize has already been called. This is a no-op'
      )
      return
    }

    console.debug('[FeatureFlagProvider].initialize', { initialFlags })

    this.set(initialFlags)
    this.ldClient = ldClient

    this.subscribeToLDChanges()

    this.initializePromise.resolve(true)
    this.hasInitialized = true

    ConnectionEventEmitter.on('online', () => {
      this.ldClient.setStreaming(true)
    })
    ConnectionEventEmitter.on('offline', () => {
      this.ldClient.setStreaming(false)
    })

    // expose functions for testing
    window['setFeatureFlag'] = featureFlags.overrideFlag.bind(featureFlags)
    window['allFeatureFlags'] = featureFlags.all.bind(featureFlags)
  }

  subscribeToLDChanges = () => {
    this.ldClient.on('change', (changes: LDFlagChangeset) => {
      const flattened = getFlattenedFlagsFromChangeset(changes, undefined)
      this.set(flattened)
    })
  }

  get<F extends keyof FeatureFlags = keyof FeatureFlags>(
    flag: F
  ): FeatureFlags[F] {
    return this.all()[flag]
  }

  set(flags: LDFlagSet): void {
    for (const [key, val] of Object.entries(flags)) {
      if (key in FEATURE_FLAG_DEFAULTS) {
        this.flags[key] = val
        this.emitOnUpdate(key as K, false) // don't process onChange fns, wait until all have been set outside of loop
      } else if (!shouldUsePublishedVersion()) {
        console.debug(
          `[GammaFeatureFlagProvider] flag ${key} not specified in FEATURE_FLAG_DEFAULTS`
        )
      }
    }
    this.store.set(this.CACHE_KEY, this.flags)
    this.processOnChangeFns()
  }

  all(): FlagMap {
    /**
     * Order of perference for values:
     *   1 - Overridden localstorage state (overrides)
     *   2 - Launch Darkly provided value (flags)
     *   3 - Default value from ./flags (defaults)
     */

    return {
      ...this.defaults,
      ...this.flags,
      ...this.overrides,
      ...USER_TESTING_OVERRIDES,
    } as FlagMap
  }

  overrideFlag(flag: K, val: FlagMap[K]): void {
    this.overrides[flag] = val
    this.emitOnUpdate(flag)
    this.store.set(this.OVERRIDES_KEY, this.overrides)
  }

  removeOverride(flag: K): void {
    delete this.overrides[flag]
    this.emitOnUpdate(flag)
    this.store.set(this.OVERRIDES_KEY, this.overrides)
  }

  updateWorkspaceInLdContext(
    workspace?: Organization
  ): Promise<void | LDFlagSet> {
    return this.initializePromise.then(() => {
      const context = {
        ...(this.ldClient.getContext() as LDMultiKindContext),
      }
      if (workspace) {
        context.workspace = {
          key: workspace.id,
          name: workspace.name,
          plan: getProductForWorkspace(workspace),
        }
      }
      return this.ldClient.identify(context)
    })
  }

  private emitOnUpdate(flag: K, processChangeFns: boolean = true) {
    const flags = this.all()

    ;(this.updateFns[flag] || [])!.forEach((fn) => {
      fn(flags[flag])
    })

    if (processChangeFns) {
      this.processOnChangeFns()
    }
  }

  private processOnChangeFns() {
    this.onChangeFns.forEach((fn) => fn())
  }
}

export const featureFlags = new FeatureFlagProvider(FEATURE_FLAG_DEFAULTS)

/**
 * Convenience hook to provide the provider and whether or not it has been initialized
 */
export const useFeatureFlagProvider = () => {
  const [hasInitialized, setInitialized] = useState<boolean>(
    featureFlags.hasInitialized
  )

  useEffect(() => {
    if (!featureFlags.hasInitialized) {
      // Ensure to update upon initialization
      featureFlags.initializePromise.then(() => {
        setInitialized(true)
      })
    } else {
      setInitialized(true)
    }
  }, [])

  return { hasInitialized, provider: featureFlags }
}
