import { Input, InputGroup, InputLeftElement } from '@chakra-ui/react'
import { GammaTooltip, gammaTheme } from '@gamma-app/ui'
import { Trans, t } from '@lingui/macro'
import { useCallback } from 'react'
import { useSelector } from 'react-redux'

import { GAMMA_PROPER_NOUN } from 'modules/i18n/properNouns'
import { MediaProviderPanelProps } from 'modules/media/types/MediaProvider'
import { ThemeColorSolid } from 'modules/theming'
import { ThemeColorPickerSolid } from 'modules/theming/components/ThemeColorPicker/ThemeColorPicker'
import { getThemeColorSolid } from 'modules/theming/utils/colors'
import { selectTheme } from 'modules/tiptap_editor/reducer'
import { useDebounced } from 'utils/hooks'

import { ColorAttrs } from './ColorAttrs'

export const ColorPanel = ({
  currentAttributes,
  updateAttributes,
}: MediaProviderPanelProps) => {
  const theme = useSelector(selectTheme)

  const setBackgroundColor = useCallback(
    (color: ThemeColorSolid) =>
      updateAttributes({ hex: getThemeColorSolid(color) }),
    [updateAttributes]
  )
  // Old background colors were stored as a value, this migrates them into an object to match other media soruces
  if (typeof currentAttributes === 'string') {
    setBackgroundColor(currentAttributes)
    return <></>
  }

  const { hex } = currentAttributes as ColorAttrs

  return (
    <>
      <ThemeColorPickerSolid
        color={{
          type: 'solid',
          color: hex,
        }}
        updateColor={setBackgroundColor}
        theme={theme}
        palette="theme"
      />
    </>
  )
}

type ColorPickerInputProps = {
  value: string | null
  defaultValue?: string
  updateValue: (color: string | null) => void
}

const maskHexValue = (str: string) => {
  return str
    .replaceAll('#', '')
    .replaceAll(/[^A-Fa-f0-9]/g, '')
    .slice(0, 6)
}

export const formatHexValue = (str: string) => {
  const rawHexValue = maskHexValue(str)
  let formattedHexValue = ''
  switch (rawHexValue.length) {
    case 0:
      // Return blank if empty or just a '#'
      return ''
    case 1:
      // Generate a hex code from 1 character
      for (let i = 0; i < 6; i++) {
        formattedHexValue += rawHexValue
      }
      break
    case 2:
      // Generate a hex code from 2 characters
      for (let i = 0; i < 3; i++) {
        formattedHexValue += rawHexValue
      }
      break
    case 3:
    case 4:
    case 5:
      // Generate a hex code from 3 characters (or malformed 4/5 character ones)
      formattedHexValue = rawHexValue
        .slice(0, 3)
        .split('')
        .map(function (hex) {
          return hex + hex
        })
        .join('')
      break
    default:
      // Return six character hex code
      formattedHexValue = rawHexValue
  }
  return '#' + formattedHexValue.toUpperCase()
}

export const ColorPickerInput = ({
  value,
  updateValue,
  defaultValue,
}: ColorPickerInputProps) => {
  const onBlur = useCallback(
    (event: React.FocusEvent<HTMLInputElement>) => {
      const formattedHexValue = formatHexValue(event.target.value)
      updateValue(formattedHexValue === '' ? null : formattedHexValue)
    },
    [updateValue]
  )
  const updateValueDebounced = useDebounced(updateValue, 100)
  return (
    <InputGroup size="md">
      <Input
        type="text"
        placeholder={
          defaultValue
            ? `${defaultValue.toUpperCase()} ${t`(${GAMMA_PROPER_NOUN} default)`}`
            : '#000000'
        }
        value={value ?? ''}
        onChange={(event) => {
          // Free people from having to type #, filter out bogus characters, and prevent them from typing too many characters
          updateValue(
            event.target.value === ''
              ? null
              : `#${maskHexValue(event.target.value)}`
          )
        }}
        onBlur={onBlur}
        onKeyDown={(event) => {
          if (event.key === 'Enter') {
            event.preventDefault()
            if (document.activeElement === event.target) {
              const inputEl = document.activeElement as HTMLElement
              inputEl.blur()
            }
          }
        }}
        fontFamily={`"SFMono-Regular",
            Consolas,
            "Liberation Mono",
            Menlo,
            Courier,
            monospace;
        `}
      />
      <InputLeftElement
        sx={{
          'input[type="color"]::-webkit-color-swatch ': {
            borderRadius: '5px',
            border: '1px solid',
            borderColor: gammaTheme.colors.gray[200],
          },
          'input[type="color"]::-webkit-color-swatch-wrapper': {
            padding: 0,
          },
        }}
      >
        <GammaTooltip label={<Trans>Color Picker</Trans>}>
          <input
            type="color"
            value={value || defaultValue}
            // https://github.com/cypress-io/cypress/issues/7812#issuecomment-964403375
            onInput={(event) =>
              updateValueDebounced(event.currentTarget.value.toUpperCase())
            }
            style={{
              border: 'none',
              background: 'none',
              width: '1.5em',
              height: '1.5em',
            }}
          />
        </GammaTooltip>
      </InputLeftElement>
    </InputGroup>
  )
}
