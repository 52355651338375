import { t } from '@lingui/macro'

import { Theme } from 'modules/theming/types'

export const getEmptyThemeName = () => t`Untitled theme`

export const EMPTY_THEME: Theme = {
  id: 'new',
  name: getEmptyThemeName(),
  archived: false,
  priority: 0,
  config: {},
  fonts: [],
}
