import memoize from 'fast-memoize'
import { sortBy } from 'lodash'

import {
  blendColors,
  isColorDark,
  isColorReadable,
  makeColorReadable,
  colorWithLightness,
} from 'utils/color'

import {
  DEFAULT_ANGLE,
  DEFAULT_GRADIENT_COLOR,
} from '../components/LinearGradientPicker/LinearGradientPicker'
import { LinearGradient } from '../components/LinearGradientPicker/types'
import { GradientStop, ThemeColor, ThemeColorGradient } from '../types'

export const getGradientCss = memoize(
  (color: ThemeColor | undefined): string | undefined => {
    if (!color || color.type !== 'linear-gradient') {
      return undefined
    }
    const sorted = sortBy(color.stops, 'position')
    return `linear-gradient(${color.angle}deg, ${sorted.map(
      (stop) => `${stop.color} ${stop.position}%`
    )})`
  }
)

export const makeGradientReadable = memoize(
  (
    gradient: ThemeColorGradient,
    contrastValue: string,
    contrastRatio?: number,
    whiteBlackOnly?: boolean
  ): ThemeColorGradient => {
    if (
      whiteBlackOnly &&
      gradient.stops.some(
        (s) => !isColorReadable(s.color, contrastValue, contrastRatio)
      )
    ) {
      const fallbackColor = isColorDark(contrastValue) ? '#FFFFFF' : '#000000'
      // If any color is not readable, fall back from gradient to solid black or white
      return {
        ...gradient,
        stops: [
          { color: fallbackColor, position: 0 },
          { color: fallbackColor, position: 100 },
        ], // CSS wont do a gradient of one color
      }
    }

    const readableColors = gradient.stops.map((s) => ({
      color: makeColorReadable(
        s.color,
        contrastValue,
        contrastRatio,
        whiteBlackOnly
      ),
      position: s.position,
    })) as ThemeColorGradient['stops']
    return {
      ...gradient,
      stops: readableColors,
    }
  }
)

export const getGradientFallbackColor = memoize(
  (gradient: ThemeColorGradient): string => {
    if (gradient.fallbackColor) {
      return gradient.fallbackColor
    }

    const sortedStops = sortBy(gradient.stops, 'position')
    const medianPosition = 50

    for (let i = 0; i < sortedStops.length - 1; i++) {
      const currentStop = sortedStops[i]
      const nextStop = sortedStops[i + 1]

      if (
        currentStop.position <= medianPosition &&
        nextStop.position >= medianPosition
      ) {
        if (currentStop.position === medianPosition) {
          return currentStop.color
        }
        if (nextStop.position === medianPosition) {
          return nextStop.color
        }

        const t =
          (medianPosition - currentStop.position) /
          (nextStop.position - currentStop.position)
        return blendColors(currentStop.color, nextStop.color, t)
      }
    }

    // If we didn't find a median, return the color of the last stop
    return sortedStops[sortedStops.length - 1].color
  }
)

export const lightenedGradient = (
  gradient: ThemeColorGradient | undefined,
  lightness: number
): ThemeColorGradient | undefined => {
  if (!gradient) return undefined
  const lightenedStops = gradient.stops.map((s) => ({
    color: colorWithLightness(s.color, lightness),
    position: s.position,
  })) as ThemeColorGradient['stops']
  return {
    ...gradient,
    stops: lightenedStops,
  }
}

export const blendedGradient = (
  gradient: ThemeColorGradient | undefined,
  blendColor: string,
  amount: number
): ThemeColorGradient | undefined => {
  if (!gradient) return undefined
  const blendedStops = gradient.stops.map((s) => ({
    color: blendColors(s.color, blendColor, amount),
    position: s.position,
  })) as ThemeColorGradient['stops']
  return {
    ...gradient,
    stops: blendedStops,
  }
}

export const mapLegacyLinearGradient = (
  gradient: LinearGradient
): ThemeColorGradient => {
  // Based on original logic in https://github.com/gamma-app/gamma/blob/d91e2f5a11ec5f5cc90058baefa18fc3d6361813/packages/client/src/modules/theming/components/LinearGradientPicker/LinearGradientPicker.tsx#L97
  const stops = gradient.colors.map<GradientStop>((color, i) => ({
    color: color || DEFAULT_GRADIENT_COLOR,
    position: (10 + i * 80) / (gradient.colors.length - 1),
  })) as ThemeColorGradient['stops']

  return {
    type: 'linear-gradient',
    stops,
    angle: gradient.angle ?? DEFAULT_ANGLE,
  }
}
