import { Box, SystemStyleObject } from '@chakra-ui/react'
import {
  faArchive,
  faCircle,
  faCircleHalfStroke,
  faEmptySet,
  faPaintbrushPencil,
} from '@fortawesome/pro-regular-svg-icons'
import { faCircle as faCircleSolid } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { gammaTheme } from '@gamma-app/ui'
import { Trans, t } from '@lingui/macro'

import { GammaIcon } from 'gamma_components/GammaIcon'

import { getSoft3DShadow } from './themeBases/soft3d'
import { Theme, ThemeKnobsV3, ThemeType } from './types'
export const DEFAULT_ACCENT_COLOR = gammaTheme.colors.trueblue[500] as string
export const DEFAULT_HEADING_COLOR = '#000000' // black
export const DEFAULT_HEADING_COLOR_DARK = '#FFFFFF' // white
export const DEFAULT_BODY_COLOR = '#272525' // dark gray
export const DEFAULT_BODY_COLOR_DARK = gammaTheme.colors.gray[200] as string
export const DEFAULT_CARD_BORDER_COLOR = '#E5E0DF' // gray.200
export const DEFAULT_CARD_BORDER_COLOR_DARK = '#565151' // gray.700
export const DEFAULT_LIGHT_CONTAINER_BACKGROUND = '#FFFFFF'
export const DEFAULT_DARK_CONTAINER_BACKGROUND = '#0C0C0C'
export const DEFAULT_CONTRAST_RATIO = 4.5 // WCAG2 standard
export const CONTRAST_RATIO_CHANGED = 6 // A higher ratio if you change the card color

export const DEFAULT_FONTS = {
  bodyFont: 'Inter',
  headingFont: 'Inter',
  fontSize: 1,
}

export const DEFAULT_FONT_WEIGHTS = {
  heading: 700,
  body: 400,
}

export const DARK_TEXT_LIGHTNESS = 0.4
export const LIGHT_TEXT_LIGHTNESS = 0.8

export const DEFAULT_LIGHT_CARD_BACKGROUND = 'white'
export const DEFAULT_DARK_CARD_BACKGROUND = 'rgba(12, 12, 12)'

export const ACCENT_IMAGE_SOURCE_KEY = 'accent.theme'

export const DEFAULT_THEME_ID = 'default-light'

export const FILTER_THEME_OPTIONS: {
  [key in ThemeType]: {
    label: JSX.Element
    ariaLabel: string
    description?: JSX.Element
    icon: JSX.Element
  }
} = {
  standard: {
    label: <Trans>Standard</Trans>,
    ariaLabel: t`Standard themes`,
    description: <Trans>These themes are created by Gamma.</Trans>,
    icon: (
      <Box boxSize="4">
        <GammaIcon />
      </Box>
    ),
  },
  custom: {
    label: <Trans>Custom</Trans>,
    ariaLabel: t`Custom themes`,
    description: <Trans>These themes are custom to your workspace.</Trans>,
    icon: <FontAwesomeIcon icon={faPaintbrushPencil} fixedWidth />,
  },
  archived: {
    label: <Trans>Archived</Trans>,
    ariaLabel: t`Archived themes`,
    icon: <FontAwesomeIcon icon={faArchive} fixedWidth />,
  },
}

export const ORDERED_FILTER_THEME_OPTIONS = ['custom', 'standard', 'archived']

export const ARCHIVED_THEME_NAME_REPLACE_REGEX = /\sarchived-[0-9a-z]{15}$/
export const THEMES_DASHBOARD_LINK = `/#themes`

type StyleDefinition<
  K extends string | undefined,
  T = Record<string, any>
> = Record<
  Exclude<K, undefined>,
  {
    name: () => string
    previewSx?: SystemStyleObject
    icon?: React.ReactNode
    deprecated?: boolean
  } & T
>

export const CARD_TRANSPARENCY_OPTIONS: StyleDefinition<
  Theme['config']['cardTransparency']
> = {
  default: {
    name: () => t`Default`,
    previewSx: {
      boxShadow: 'none',
    },
  },
  frosted: {
    name: () => t`Frosted`,
    previewSx: {
      boxShadow: 'lg',
      bg: 'rgba(255,255,255,0.7)',
      backdropFilter: 'blur(3px)',
    },
  },
  faded: {
    name: () => t`Faded`,
    previewSx: {
      boxShadow: 'lg',
      bg: 'rgba(255,255,255,0.75)',
    },
  },
} as const

export const ROUNDNESS_OPTIONS: StyleDefinition<
  Theme['config']['roundness'],
  {
    cardRadius: number
    blockRadius: number
  }
> = {
  sm: {
    name: () => t`Square`,
    previewSx: {
      borderRadius: '4',
    },
    cardRadius: 0.125,
    blockRadius: 0.125,
  },
  md: {
    name: () => t`Default`,
    previewSx: {
      borderRadius: '8',
    },
    cardRadius: 0.6,
    blockRadius: 0.35,
  },
  lg: {
    name: () => t`Round`,
    previewSx: {
      borderRadius: '12',
    },
    cardRadius: 1.2,
    blockRadius: 0.75,
  },
  xl: {
    name: () =>
      t({
        message: `Super round`,
        comment: `Refers to the level of roundness on a shape - in this case, very smooth, rounded corners`,
      }),
    previewSx: {
      borderRadius: '20',
    },
    cardRadius: 2,
    blockRadius: 1.25,
  },
} as const

export const CARD_SHADOW_OPTIONS: StyleDefinition<
  Theme['config']['cardShadow'],
  {
    shadow: string
  }
> = {
  none: {
    name: () => t`None`,
    previewSx: {
      boxShadow: 'none',
    },
    shadow: 'none',
  },
  default: {
    name: () => t`Default`,
    previewSx: {
      boxShadow: 'lg',
    },
    shadow:
      '0 0.25em 0.375em -0.0625em rgba(0, 0, 0, 0.1), 0 0.125em 0.25em -0.0625em rgba(0, 0, 0, 0.06)',
  },
  hard: {
    name: () =>
      t({
        message: `Hard`,
        comment: `A hard shadow with sharp, angular edges as opposed to a soft/blurry shadow`,
      }),
    previewSx: {
      boxShadow: '4px 4px 0 0 rgba(0, 0, 0, 0.4)',
    },
    shadow:
      'var(--card-shadow-distance) var(--card-shadow-distance) 0 0 var(--card-border-color)',
  },
  soft3d: {
    name: () =>
      t({
        message: `Soft 3D`,
        comment: `A soft shadow with rounded edges that gives the appearance of a rounded 3D object`,
      }),
    previewSx: {
      boxShadow: `-0.375em -0.375em 0.75em rgba(0, 0, 0, 0.25), 0.375em 0.375em 0.75em rgba(255, 255, 255, 0.25)`,
    },
    deprecated: true,
    // Overridden by getSoft3DShadow
    shadow: ``,
  },
} as const

export const BORDER_OPTIONS: StyleDefinition<
  Theme['config']['cardBorder'],
  {
    cardBorderEm: number
    shapeBorderEm: number
    lineThicknessEm: number
    shadowSizeEm: number
    cardShadowSizeEm: number
  }
> = {
  none: {
    name: () => t`None`,
    icon: <FontAwesomeIcon icon={faEmptySet} />,
    previewSx: {
      borderWidth: '0px',
    },
    cardBorderEm: 0,
    shapeBorderEm: 0,
    lineThicknessEm: 0.125,
    shadowSizeEm: 0.125,
    cardShadowSizeEm: 0.5,
  },
  sm: {
    name: () => t`Thin`,
    icon: <Box w={6} h={0.5} bg="gray.900" borderRadius="md" />,
    previewSx: {
      borderWidth: '1px',
      borderStyle: 'solid',
    },
    cardBorderEm: 0.0625,
    shapeBorderEm: 0.0625,
    lineThicknessEm: 0.125,
    shadowSizeEm: 0.125,
    cardShadowSizeEm: 0.4,
  },
  md: {
    name: () => t`Medium`,
    icon: <Box w={6} h={1} bg="gray.900" borderRadius="md" />,
    previewSx: {
      borderWidth: '2px',
      borderStyle: 'solid',
    },
    cardBorderEm: 0.125,
    shapeBorderEm: 0.125,
    lineThicknessEm: 0.125,
    shadowSizeEm: 0.15,
    cardShadowSizeEm: 0.5,
  },
  lg: {
    name: () => t`Thick`,
    icon: <Box w={6} h={2} bg="gray.900" borderRadius="md" />,
    previewSx: {
      borderWidth: '4px',
      borderStyle: 'solid',
    },
    cardBorderEm: 0.25,
    shapeBorderEm: 0.175,
    lineThicknessEm: 0.25,
    shadowSizeEm: 0.2,
    cardShadowSizeEm: 0.7,
  },
} as const

export const BUTTON_ROUNDNESS_OPTIONS: StyleDefinition<
  Theme['config']['buttonRoundness'],
  {
    borderRadius: string
  }
> = {
  sm: {
    name: () =>
      t({
        message: `Square`,
        comment: `Refers to the level of roundness on a shape - in this case, sharp, rectangular corners`,
      }),
    previewSx: {
      borderRadius: '1.5',
    },
    borderRadius: '0.125em',
  },
  md: {
    name: () => t`Default`,
    previewSx: {
      borderRadius: '4',
    },
    borderRadius: '0.375em',
  },
  lg: {
    name: () => t`Round`,
    previewSx: {
      borderRadius: '8',
    },
    borderRadius: '0.75em',
  },
  xl: {
    name: () =>
      t({
        message: `Capsule`,
        comment: `Refers to the level of roundness on a shape - in this case, very smooth, rounded corners`,
      }),
    previewSx: {
      borderRadius: 'full',
    },
    borderRadius: '8em',
  },
} as const

export const SHAPE_FILL_OPTIONS: StyleDefinition<
  Theme['config']['shapeFill'],
  {
    opacity: number
  }
> = {
  solid: {
    name: () => t`Solid`,
    icon: <FontAwesomeIcon icon={faCircleSolid} />,
    previewSx: {
      backgroundColor: 'gray.200',
    },
    opacity: 1,
  },
  semi: {
    name: () => t`Semi`,
    icon: <FontAwesomeIcon icon={faCircleHalfStroke} />,
    previewSx: {
      backgroundColor: 'gray.100',
    },
    opacity: 0.5,
  },
  none: {
    name: () => t`None`,
    icon: <FontAwesomeIcon icon={faCircle} />,
    previewSx: {
      backgroundColor: 'transparent',
    },
    opacity: 0,
  },
} as const

export const SHAPE_SHADOW_OPTIONS: StyleDefinition<
  Theme['config']['shapeShadow'],
  | {
      shadowBlur: string
      shadowDistance: number // As a multiple of border size
    }
  | {
      getShadow: (cardColor: string) => string
      getClickShadow: (cardColor: string) => string
      shapeFilter: string
    }
> = {
  none: {
    name: () => t`None`,
    previewSx: {
      boxShadow: 'none',
    },
    shadowDistance: 0,
    shadowBlur: '0',
    shapeFilter: 'none',
  },
  soft: {
    name: () => t`Soft`,
    previewSx: {
      boxShadow: 'var(--chakra-shadows-md)',
    },
    shadowBlur: '1em',
    shadowDistance: 0,
  },
  hard: {
    name: () => t`Hard`,
    previewSx: {
      boxShadow: '3px 3px 0 0 rgba(0, 0, 0, 0.4)',
    },
    shadowBlur: '0',
    shadowDistance: 0.75,
  },
  soft3d: {
    name: () => t`Soft 3D`,
    previewSx: {
      boxShadow: `inset -0.125em -0.125em 0.25em rgba(255, 255, 255, 0.25), inset 0.125em 0.125em 0.25em rgba(0, 0, 0, 0.25)`,
    },
    deprecated: true,
    getShadow: (cardColor) => getSoft3DShadow(cardColor, 0.125, true),
    getClickShadow: (cardColor) => getSoft3DShadow(cardColor, 0.125, false),
    shapeFilter: 'url(#theme-shape-shadow)',
  },
} as const

export const SHAPE_COLOR_SCHEME_OPTIONS: StyleDefinition<
  Theme['config']['shapeColorScheme'],
  {
    tooltip?: () => string
  }
> = {
  default: {
    name: () => t`Subtle`,
    tooltip: () => t`A tint or shade of your card background color`,
  },
  accent: {
    name: () => t`Primary accent color`,
    tooltip: () =>
      t`We adjust your primary accent color for use as a background. Choose custom if you want your exact color`,
  },
  custom: {
    name: () => t`Custom`,
  },
} as const

export const THEME_KNOB_DEFAULTS: ThemeKnobsV3 = {
  cardTransparency: 'default',
  roundness: 'md',
  buttonRoundness: 'md',
  cardShadow: 'default',
  cardBorder: 'sm',
  shapeFill: 'solid',
  shapeShadow: 'none',
  shapeBorder: 'sm',
  shapeColorScheme: 'default',
  accentCut: 'default',
}

export const PREVIEW_FONT_SIZES_FOR_BREAKPOINTS = {
  base: '0.5rem',
  md: '0.625rem',
  xl: '0.75rem',
  '3xl': '1rem',
}
