import {
  Divider,
  Flex,
  HStack,
  IconButton,
  SimpleGrid,
  Slider,
  SliderThumb,
  SliderTrack,
  Text,
  VStack,
} from '@chakra-ui/react'
import { faPlus, faTrash } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { GammaTooltip } from '@gamma-app/ui'
import { Trans, t } from '@lingui/macro'
import { indexOf } from 'lodash'
import { useCallback, useState } from 'react'

import { GradientStop, ThemeColorGradient } from 'modules/theming/types'
import { getGradientCss } from 'modules/theming/utils/gradient'
import { lerpColor } from 'utils/color'

import {
  DEFAULT_COLOR,
  EyeDropper,
  HexColorInput,
  HueSlider,
  SaturationBrightnessCanvas,
} from './SolidColorPicker'
import { ThemeColorIcon } from './ThemeColorIcon'
import { ThemeColorPickerProps, ThemePalette } from './ThemeColorPicker'

const MAX_STOPS = 5
export const DEFAULT_ANGLE = 45

type GradientPickerProps = ThemeColorPickerProps & {
  color: ThemeColorGradient
  updateColor: (color: ThemeColorGradient) => void
}

export const LinearGradientPicker = (props: GradientPickerProps) => {
  const { updateColor, color } = props
  const [currentStopIndex, setCurrentStopIndex] = useState(0)
  const currentStop = color.stops[currentStopIndex]

  const updateStop = useCallback(
    (index: number, stop: GradientStop | undefined) => {
      const newStops: ThemeColorGradient['stops'] = [...color.stops]
      if (stop === undefined) {
        newStops.splice(index, 1)
        setCurrentStopIndex(Math.min(index, newStops.length - 1))
      } else {
        newStops[index] = stop
        setCurrentStopIndex(index)
      }
      updateColor({ ...color, stops: newStops })
    },
    [color, updateColor]
  )
  const addStop = useCallback(
    (newStop: GradientStop) => {
      const newStops: ThemeColorGradient['stops'] = [...color.stops]
      newStops.push(newStop)
      newStops.sort((a, b) => a.position - b.position)
      setCurrentStopIndex(indexOf(newStops, newStop))
      updateColor({ ...color, stops: newStops })
    },
    [color, updateColor]
  )

  const onColorChange = useCallback(
    (newColor: string) => {
      updateStop(currentStopIndex, { ...currentStop, color: newColor })
    },
    [currentStop, currentStopIndex, updateStop]
  )

  return (
    <VStack align="start" spacing={4}>
      <GradientStopsSlider
        {...props}
        addStop={addStop}
        currentStop={currentStop}
        updateStop={updateStop}
        currentStopIndex={currentStopIndex}
        setCurrentStopIndex={setCurrentStopIndex}
      />
      <ThemePalette
        {...props}
        updateColor={(newColor) => {
          if (newColor.type === 'linear-gradient') {
            updateColor(newColor)
          } else {
            onColorChange(newColor?.color)
          }
        }}
      />
      <Divider />
      <VStack>
        <SaturationBrightnessCanvas
          color={currentStop.color}
          onChange={onColorChange}
        />
        <HStack w="100%" spacing={4}>
          <ThemeColorIcon color={color} />
          <HueSlider color={currentStop.color} onChange={onColorChange} />
          <EyeDropper color={currentStop.color} onChange={onColorChange} />
        </HStack>
        <HexColorInput
          color={currentStop.color}
          updateColor={onColorChange}
          placeholder={DEFAULT_COLOR.color}
        />
        <GradientAnglePicker {...props} />
      </VStack>
    </VStack>
  )
}

type GradientStopsSliderProps = GradientPickerProps & {
  currentStop: GradientStop
  updateStop: (index: number, stop: GradientStop | undefined) => void
  addStop: (newStop: GradientStop) => void
  currentStopIndex: number
  setCurrentStopIndex: (index: number) => void
}

const GradientStopsSlider = ({
  color,
  updateStop,
  currentStopIndex,
  setCurrentStopIndex,
  addStop,
}: GradientStopsSliderProps) => {
  const { stops } = color
  const canDelete = stops.length > 2
  const canAdd = stops.length < MAX_STOPS

  return (
    <HStack w="100%" spacing={0}>
      <Flex
        borderRadius="full"
        background={getGradientCss({ ...color, angle: 90 })}
        pos="relative"
        h={3}
        border="1px solid"
        borderColor="gray.200"
        flex={1}
        mr={2}
      >
        {stops.map((stop, index) => {
          const isCurrent = index === currentStopIndex
          return (
            <Flex key={index} pos="absolute" inset={0} top={-1.5}>
              <Slider
                size="lg"
                value={stop.position}
                min={0}
                max={100}
                step={1}
                onChange={(position) => {
                  updateStop(index, { ...stop, position })
                }}
                onClick={() => setCurrentStopIndex(index)}
              >
                <SliderTrack bg="transparent" h={3}></SliderTrack>
                <SliderThumb
                  borderWidth={3}
                  borderColor="white"
                  boxSize="24px"
                  shadow="md"
                  outline={isCurrent ? '2px solid' : '1px solid'}
                  outlineColor={isCurrent ? 'trueblue.300' : 'blackAlpha.200'}
                  bg={stop.color}
                />
              </Slider>
            </Flex>
          )
        })}
      </Flex>
      <GammaTooltip
        label={
          <Trans comment="Removes a color step from a gradient">
            Remove this stop
          </Trans>
        }
      >
        <IconButton
          icon={<FontAwesomeIcon icon={faTrash} />}
          aria-label={t({
            message: 'Remove this stop',
            comment: 'Removes a color step from a gradient',
          })}
          onClick={() => updateStop(currentStopIndex, undefined)}
          size="sm"
          variant="ghost"
          isDisabled={!canDelete}
        />
      </GammaTooltip>
      <GammaTooltip
        label={
          <Trans comment="Adds a color step to a gradient">Add stop</Trans>
        }
      >
        <IconButton
          icon={<FontAwesomeIcon icon={faPlus} />}
          aria-label={t({
            message: 'Add stop',
            comment: 'Adds a color step to a gradient',
          })}
          onClick={() => {
            addStop(findBestStopPosition(color))
          }}
          size="sm"
          variant="ghost"
          isDisabled={!canAdd}
        />
      </GammaTooltip>
    </HStack>
  )
}

const GradientAnglePicker = (props: GradientPickerProps) => {
  const { color, updateColor } = props
  return (
    <VStack align="start" w="100%">
      <Text fontWeight="600" fontSize="sm" color="gray.500">
        <Trans comment="An angle between 0 and 360 degrees">Angle</Trans>
      </Text>
      <SimpleGrid columns={4} spacing={2} w="100%">
        {ANGLES.map((angle) => {
          const isSelected = color.angle === angle.value

          return (
            <ThemeColorIcon
              key={angle.value}
              color={{ ...color, angle: angle.value }}
              onClick={() => updateColor({ ...color, angle: angle.value })}
              isActive={isSelected}
              buttonProps={{
                w: '100%',
              }}
              name={angle.label}
            />
          )
        })}
      </SimpleGrid>
    </VStack>
  )
}

const findBestStopPosition = (gradient: ThemeColorGradient) => {
  let stopA: GradientStop = gradient.stops[0],
    stopB: GradientStop = gradient.stops[1]
  const bestPosition = gradient.stops.reduce(
    (acc, stop, index) => {
      const nextStop = gradient.stops[index + 1]
      if (!nextStop) return acc

      const distance = nextStop.position - stop.position
      if (distance > acc.distance) {
        stopA = stop
        stopB = nextStop
        return {
          distance,
          position: stop.position + distance / 2,
        }
      }

      return acc
    },
    { distance: 0, position: 0 }
  )

  return {
    color: lerpColor(stopA.color, stopB.color, bestPosition.position / 100),
    position: bestPosition.position,
  }
}

const ANGLES = [
  { label: '0°', value: 0 },
  { label: '45°', value: 45 },
  { label: '90°', value: 90 },
  { label: '135°', value: 135 },
  { label: '180°', value: 180 },
  { label: '225°', value: 225 },
  { label: '270°', value: 270 },
  { label: '315°', value: 315 },
]
