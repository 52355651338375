import { Theme } from '../types'

export const isThemeGlassy = (config: Theme['config']) => {
  if (config.cardTransparency) {
    return (
      config.cardTransparency === 'frosted' ||
      config.cardTransparency === 'faded'
    )
  }
  return config.themeBase === 'glass' || config.themeBase === 'daydream'
}
