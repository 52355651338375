import {
  Box,
  HStack,
  IconButton,
  Input,
  Slider,
  SliderThumb,
  SliderTrack,
  VStack,
} from '@chakra-ui/react'
import { faEyeDropper } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { t } from '@lingui/macro'
import clamp from 'lodash/clamp'
import {
  ChangeEvent,
  MouseEvent,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react'
import tinycolor from 'tinycolor2'

import { ThemeColorSolid } from 'modules/theming/types'
import { getThemeColorSolid } from 'modules/theming/utils/colors'
import { formatHexValue } from 'modules/tiptap_editor/components/panels/ColorPanel'
import { isHexColor } from 'utils/color'

import { ThemeColorIcon } from './ThemeColorIcon'
import { ThemeColorPickerProps, ThemePalette } from './ThemeColorPicker'

export const DEFAULT_COLOR: ThemeColorSolid = {
  type: 'solid',
  color: '#FF0000',
}

export const SolidColorPicker = (props: ThemeColorPickerProps) => {
  const color = props.color || props.defaultColor || DEFAULT_COLOR
  const hex = getThemeColorSolid(color)
  const { updateColor } = props
  const onChange = useCallback(
    (newColor: string) => {
      updateColor({
        type: 'solid',
        color: newColor,
      })
    },
    [updateColor]
  )

  return (
    <VStack align="start" spacing={4}>
      <ThemePalette {...props} />
      <VStack>
        <SaturationBrightnessCanvas color={hex} onChange={onChange} />
        <HStack w="100%" spacing={4}>
          <ThemeColorIcon color={color} />
          <HueSlider color={hex} onChange={onChange} />
          <EyeDropper color={hex} onChange={onChange} />
        </HStack>
        <HexColorInput
          initialFocusRef={props.initialFocusRef}
          color={hex}
          updateColor={onChange}
          placeholder={DEFAULT_COLOR.color}
        />
      </VStack>
    </VStack>
  )
}

export const HexColorInput = ({
  color,
  updateColor,
  placeholder,
  initialFocusRef,
}: {
  color: string
  updateColor: (color: string) => void
  placeholder?: string
  initialFocusRef?: React.RefObject<HTMLInputElement>
}) => {
  const [value, setValue] = useState(color)
  const [didInitialFocus, setDidInitialFocus] = useState(false)
  useEffect(() => {
    setValue(color)
  }, [color])

  const onChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      const newValue = e.target.value
      setValue(newValue)
      if (isHexColor(newValue)) {
        updateColor(newValue)
      }
    },
    [updateColor]
  )

  const onDone = useCallback(
    (e) => {
      const val = formatHexValue(e.target.value)
      if (isHexColor(val)) {
        updateColor(val)
      }
    },
    [updateColor]
  )

  // Select the text when the input is focused, but only once
  useEffect(() => {
    if (initialFocusRef && !didInitialFocus) {
      initialFocusRef.current?.select()
      setDidInitialFocus(true)
    }
  }, [didInitialFocus, initialFocusRef])

  return (
    <Input
      ref={initialFocusRef}
      type="text"
      value={value}
      onChange={onChange}
      onBlur={onDone}
      onKeyDown={(ev) => {
        if (ev.key === 'Enter') {
          onDone(ev)
        }
      }}
      fontFamily="mono"
      placeholder={placeholder}
    />
  )
}

type ColorPickerProps = {
  color: tinycolor.ColorInput
  onChange: (color: string) => void
}

export const HueSlider = ({ color, onChange }: ColorPickerProps) => {
  const hsv = tinycolor(color).toHsv()
  const [selectedHue, setSelectedHue] = useState(hsv.h)
  useEffect(() => {
    setSelectedHue(hsv.h)
  }, [hsv.h])

  return (
    <Slider
      min={0}
      max={360}
      defaultValue={selectedHue}
      value={selectedHue}
      size="lg"
      onChange={(hue) => {
        setSelectedHue(hue)
        onChange(tinycolor({ h: hue, s: hsv.s, v: hsv.v }).toHexString())
      }}
      step={0.1}
      focusThumbOnChange={false}
    >
      <SliderTrack
        h={3}
        borderRadius="full"
        bgGradient="linear(to-r, hsl(0, 100%, 50%), 
hsl(60, 100%, 50%), 
hsl(120, 100%, 50%), 
hsl(180, 100%, 50%), 
hsl(240, 100%, 50%), 
hsl(300, 100%, 50%), 
hsl(360, 100%, 50%))"
      />
      <SliderThumb
        borderWidth={3}
        borderColor="white"
        bg={`hsl(${hsv.h}, 100%, 50%)`}
        boxSize="24px"
        shadow="md"
        outline="1px solid"
        outlineColor="blackAlpha.200"
      />
    </Slider>
  )
}

export const SaturationBrightnessCanvas = ({
  color,
  onChange,
}: ColorPickerProps) => {
  const [mouseIsDown, setMouseDown] = useState(false)
  const canvasRef = useRef<HTMLCanvasElement>(null)
  const cursorRef = useRef<HTMLDivElement>(null)
  const canvasContainerRef = useRef<HTMLDivElement>(null)
  const hsv = tinycolor(color).toHsv()
  const baseColor = tinycolor({ h: hsv.h, s: 100, v: 100 })

  const positionToColor = useCallback(
    (x: number, y: number) => {
      if (!canvasRef.current) return
      const saturation = x / canvasRef.current.width
      const lightness = 1 - y / canvasRef.current.height

      hsv.s = saturation
      hsv.v = lightness
      return hsv
    },
    [hsv]
  )

  const colorToPosition = useCallback((c: tinycolor.ColorInput) => {
    if (!canvasRef.current) return { x: 0, y: 0 }
    const newHsv = tinycolor(c).toHsv()
    const saturation = newHsv.s
    const lightness = newHsv.v

    const x = saturation * canvasRef.current.width
    const y = (1 - lightness) * canvasRef.current.height

    return { x, y }
  }, [])

  const drawGradient = useCallback(() => {
    // Set things up
    const canvas = canvasRef.current
    const ctx = canvas?.getContext('2d')
    if (!canvas || !ctx) return

    const width = canvas.width
    const height = canvas.height

    // Create gradient for saturation
    const saturationGradient = ctx.createLinearGradient(0, 0, width, 0)
    saturationGradient.addColorStop(0, 'white')
    saturationGradient.addColorStop(1, baseColor.toHexString())

    // Fill with gradient for saturation
    ctx.fillStyle = saturationGradient
    ctx.fillRect(0, 0, width, height)

    // Create gradient for lightness
    const lightnessGradient = ctx.createLinearGradient(0, 0, 0, height)
    lightnessGradient.addColorStop(0, 'rgba(0,0,0,0)')
    lightnessGradient.addColorStop(1, 'black')

    // Fill with gradient for lightness
    ctx.fillStyle = lightnessGradient
    ctx.fillRect(0, 0, width, height)
  }, [baseColor])

  const setDesiredCursorPosition = useCallback((x: number, y: number) => {
    if (!canvasRef.current || !cursorRef.current) return { x: 0, y: 0 }
    const minX = 0
    const minY = 0

    const maxX = canvasRef.current.clientWidth
    const maxY = canvasRef.current.clientHeight

    const xPos = clamp(x, minX, maxX)
    const yPos = clamp(y, minY, maxY)

    cursorRef.current.style.left = `${xPos}px`
    cursorRef.current.style.top = `${yPos}px`

    return { x: xPos, y: yPos }
  }, [])

  useEffect(() => {
    drawGradient()

    // Set the cursor position
    const { x, y } = colorToPosition(color)
    setDesiredCursorPosition(x, y)
  }, [color, colorToPosition, drawGradient, setDesiredCursorPosition])

  const handleMouseMove = useCallback(
    (ev) => {
      if (!canvasContainerRef.current) return

      const rect = canvasContainerRef.current.getBoundingClientRect()
      const { x: xPos, y: yPos } = setDesiredCursorPosition(
        ev.clientX - rect.left,
        ev.clientY - rect.top
      )

      const newColor = positionToColor(xPos, yPos)
      if (!newColor) return

      onChange(tinycolor(newColor).toHexString())
      ev.preventDefault()
    },
    [onChange, positionToColor, setDesiredCursorPosition]
  )
  const handleMouseUp = useCallback((ev) => {
    setMouseDown(false)
    ev.preventDefault()
  }, [])

  const handleMouseDown = useCallback(
    (ev: MouseEvent) => {
      setMouseDown(true)
      handleMouseMove(ev)
      ev.preventDefault()
    },
    [handleMouseMove]
  )

  useEffect(() => {
    if (!mouseIsDown) return
    document.addEventListener('mousemove', handleMouseMove)
    document.addEventListener('mouseup', handleMouseUp)

    return () => {
      document.removeEventListener('mousemove', handleMouseMove)
      document.removeEventListener('mouseup', handleMouseUp)
    }
  }, [handleMouseMove, handleMouseUp, mouseIsDown])

  return (
    <Box pos="relative" ref={canvasContainerRef} onMouseDown={handleMouseDown}>
      <Box
        ref={cursorRef}
        pos="absolute"
        w={5}
        h={5}
        border="2px solid white"
        borderRadius="full"
        transform={`translate(-50%, -50%) scale(${mouseIsDown ? 1.2 : 1})`}
        boxShadow="md"
        transitionProperty="transform"
        transitionDuration="normal"
        bg={tinycolor(color).toHexString()}
        outline="1px solid"
        outlineColor="blackAlpha.200"
      />

      <Box
        as="canvas"
        ref={canvasRef}
        width="100%"
        height="150px"
        borderRadius="md"
        shadow="base"
        border="1px solid"
        borderColor="gray.200"
      />
    </Box>
  )
}

export const EyeDropper = ({ onChange }: ColorPickerProps) => {
  const handleDropperClick = useCallback(() => {
    // @ts-ignore - case where this isnt defined handled below
    const eyeDropper = new window.EyeDropper()
    eyeDropper
      .open()
      .then((result) => {
        onChange(result.sRGBHex)
      })
      .catch((err) => {
        console.error('Eyedropper error:', err)
      })
  }, [onChange])

  // Only works in Chrome and Edge, not Safari and Firefox
  if (!('EyeDropper' in window)) {
    return null
  }

  return (
    <IconButton
      size="sm"
      icon={<FontAwesomeIcon fixedWidth icon={faEyeDropper} />}
      onClick={handleDropperClick}
      variant="ghost"
      aria-label={t({
        message: `Pick color`,
        comment: 'Opens an eye dropper to choose a color',
      })}
    />
  )
}
