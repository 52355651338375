import {
  IconButton,
  Input,
  InputGroup,
  InputLeftElement,
  InputRightElement,
  forwardRef,
} from '@chakra-ui/react'
import { faUndo } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { GammaTooltip } from '@gamma-app/ui'
import { Trans, t } from '@lingui/macro'

import { ThemeColor } from 'modules/theming/types'
import { getThemeColorLabel } from 'modules/theming/utils/colors'

import { ThemeColorIcon } from './ThemeColorIcon'

type ThemeColorPickerInputProps = {
  color?: ThemeColor
  defaultColor?: ThemeColor
  updateColor: (color: ThemeColor | undefined) => void
  onClick?: () => void
  canReset?: boolean
}

export const ThemeColorPickerInput = forwardRef(
  (
    {
      color,
      updateColor,
      defaultColor = {
        type: 'solid',
        color: '#000000',
      },
      onClick,
      canReset,
    }: ThemeColorPickerInputProps,
    ref
  ) => {
    return (
      <InputGroup size="md">
        <Input
          type="text"
          placeholder={
            defaultColor
              ? `${getThemeColorLabel(defaultColor)} ${t`(default)`}`
              : t`Default`
          }
          value={color ? getThemeColorLabel(color) : ''}
          readOnly
          fontFamily="monospace"
          bg="white"
          onFocus={onClick}
        />
        <InputLeftElement>
          <ThemeColorIcon
            color={color || defaultColor}
            ref={ref}
            onClick={onClick}
            size={7}
          />
        </InputLeftElement>
        {canReset && (
          <InputRightElement>
            <GammaTooltip
              label={<Trans>Reset to default</Trans>}
              placement="top"
            >
              <IconButton
                aria-label={t`Delete`}
                variant="ghost"
                onClick={() => updateColor(undefined)}
                icon={<FontAwesomeIcon icon={faUndo} />}
                size="sm"
                colorScheme="red"
              />
            </GammaTooltip>
          </InputRightElement>
        )}
      </InputGroup>
    )
  }
)
ThemeColorPickerInput.displayName = 'ThemeColorPickerInput'
