import * as Sentry from '@sentry/browser'
import { useEffect, useMemo } from 'react'

import { cannyIdentify } from 'modules/canny/fns'
import { useReconnectedToast } from 'modules/connection/hooks/useReconnectedToast'
import { useRefreshCredits } from 'modules/credits/hooks'
import { updateIntercomUser } from 'modules/intercom/fns'
import { useAppSelector, useAppStore } from 'modules/redux'
import { selectCurrentWorkspace, selectUserState } from 'modules/user/reducer'

import { useApolloConnectOnSignin } from '../hooks/useApolloConnectOnSignin'
import { useAutoSetUserLocale } from '../hooks/useAutoSetUserLocale'
import { useRefreshUser } from '../hooks/useRefreshUser'
import { useSetLoggedInCookieForCustomVersions } from '../hooks/useSetLoggedInCookieForCustomVersions'
import { useUserApi } from '../hooks/useUserApi'
import { userEventEmitter } from '../UserEventEmitter'
import { getHasLoggedInCookie } from '../utils'
import { AbilityContext } from './AuthContext'
import { UserContext, UserContextType } from './UserContext'

type UserContextProps = {
  children: React.ReactNode
}

export const UserContextProvider = ({
  children,
}: UserContextProps): JSX.Element => {
  useSetLoggedInCookieForCustomVersions()
  const { fetchUser, updateUserSettings, setCurrentWorkspaceId } = useUserApi()
  const store = useAppStore()
  const userState = useAppSelector(selectUserState)
  const currentWorkspace = useAppSelector(selectCurrentWorkspace)

  // if we have a logged in cookie kick off the fetch
  useEffect(() => {
    if (getHasLoggedInCookie()) {
      fetchUser()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // piece together the User reducer state with other things that the UserContext
  // consumers expect
  const contextState = useMemo<UserContextType>(() => {
    return {
      ...userState,
      setCurrentWorkspaceId,
      currentWorkspace,
      isUserLoading: userState.userStatus === 'loading',
      refetch: fetchUser,
      setSettings: updateUserSettings,
    }
  }, [
    userState,
    setCurrentWorkspaceId,
    currentWorkspace,
    fetchUser,
    updateUserSettings,
  ])

  useReconnectedToast()
  useRefreshUser()
  useApolloConnectOnSignin()
  useRefreshCredits()
  useAutoSetUserLocale()
  useEffect(() => {
    return userEventEmitter.on('userFetched', ({ user }) => {
      const { currentWorkspaceId, anonymousUser } = selectUserState(
        store.getState()
      )
      const currentWs = selectCurrentWorkspace(store.getState())

      Sentry.configureScope((scope) => scope.setUser(null))
      if (!user) {
        // we've been signed out
        Sentry.setUser({
          anonymousUserId: anonymousUser.id,
          anonymousUserDisplayName: anonymousUser.displayName,
          isAnonymousUser: true,
        })
      } else {
        Sentry.setUser({
          id: user.id,
          orgId: currentWorkspaceId,
        })

        cannyIdentify({ user })
        updateIntercomUser({ user, currentWorkspace: currentWs })
      }
    })
  }, [store])

  return (
    <UserContext.Provider value={contextState}>
      <AbilityContext.Provider value={userState.userAbility}>
        {children}
      </AbilityContext.Provider>
    </UserContext.Provider>
  )
}
