/**
 * Used by both the ThemeBuilder and the ThemeEditor.
 * The ThemeImporter has its own reducer because its state also relies on suggestions
 * returned from the AI
 */
import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import { CreateThemeInput } from 'modules/api'
import { ImageAttrs } from 'modules/media/types/Image'
import { RootState } from 'modules/redux'
import {
  FontMap,
  Theme,
  ThemeColor,
  ThemeColorSolid,
} from 'modules/theming/types'
import { EMPTY_THEME } from 'modules/theming/utils/emptyTheme'
import { getUpdatedThemeFonts } from 'modules/theming/utils/fonts'

import { FontPair } from './types'

export type ThemeEditingState = {
  theme: Theme
  baseTheme: Theme | null
}

const initialState: ThemeEditingState = {
  theme: EMPTY_THEME,
  baseTheme: null,
}

const ThemeEditingSlice = createSlice({
  name: 'ThemeEditing',
  initialState,
  reducers: {
    resetThemeEditingState: () => initialState,
    setBaseTheme(
      state: ThemeEditingState,
      action: PayloadAction<{ baseTheme: Theme }>
    ) {
      state.theme = {
        ...state.theme,
        ...action.payload.baseTheme,
      }
    },
    updateThemeName(
      state: ThemeEditingState,
      action: PayloadAction<{ name: string }>
    ) {
      state.theme.name = action.payload.name
    },
    updateCardColor(
      state: ThemeEditingState,
      action: PayloadAction<{ color: ThemeColorSolid }>
    ) {
      state.theme.config.cardColor = action.payload.color
    },
    updatePrimaryAccentColor(
      state: ThemeEditingState,
      action: PayloadAction<{ color: ThemeColorSolid }>
    ) {
      state.theme.config.primaryColor = action.payload.color
      state.theme.config.accentBackgrounds = undefined
      state.theme.config.stylePrompt = undefined
    },
    updateHeadingFont(
      state: ThemeEditingState,
      action: PayloadAction<{
        font: string
        fontsMap: FontMap
      }>
    ) {
      const { font, fontsMap } = action.payload
      state.theme.headingFont = font
      state.theme.fonts = getUpdatedThemeFonts({
        fontsMap: fontsMap,
        headingFontId: font,
        bodyFontId: state.theme.bodyFont,
      })
    },
    updateBodyFont(
      state: ThemeEditingState,
      action: PayloadAction<{ font: string; fontsMap: FontMap }>
    ) {
      const { fontsMap, font } = action.payload
      state.theme.bodyFont = font
      state.theme.fonts = getUpdatedThemeFonts({
        fontsMap: fontsMap,
        bodyFontId: font,
        headingFontId: state.theme.headingFont,
      })
    },
    updateHeadingFontWeight(
      state: ThemeEditingState,
      action: PayloadAction<{ weight: Theme['headingFontWeight'] }>
    ) {
      state.theme.headingFontWeight = action.payload.weight
    },
    updateBodyFontWeight(
      state: ThemeEditingState,
      action: PayloadAction<{ weight: Theme['bodyFontWeight'] }>
    ) {
      state.theme.bodyFontWeight = action.payload.weight
    },
    updateBodyColor(
      state: ThemeEditingState,
      action: PayloadAction<{ color: ThemeColorSolid }>
    ) {
      state.theme.config.bodyColor = action.payload.color
    },
    updateHeadingColor(
      state: ThemeEditingState,
      action: PayloadAction<{ color: ThemeColor }>
    ) {
      state.theme.config.headingColor = action.payload.color
    },
    updateFontPair(
      state: ThemeEditingState,
      action: PayloadAction<{ fontPair: FontPair; fontsMap: FontMap }>
    ) {
      const { fontPair, fontsMap } = action.payload
      state.theme.headingFont = fontPair.heading.font
      state.theme.bodyFont = fontPair.body.font
      state.theme.headingFontWeight = fontPair.heading.weight
      state.theme.bodyFontWeight = fontPair.body.weight
      state.theme.fonts = getUpdatedThemeFonts({
        fontsMap: fontsMap,
        headingFontId: fontPair.body.font,
        bodyFontId: fontPair.heading.font,
      })
    },
    updateConfig(
      state: ThemeEditingState,
      action: PayloadAction<{ config: Theme['config'] }>
    ) {
      state.theme.config = {
        ...state.theme.config,
        ...action.payload.config,
      }
    },
    updateLogo(
      state: ThemeEditingState,
      action: PayloadAction<{ url?: string; logoImage: ImageAttrs }>
    ) {
      state.theme.logoUrl = action.payload.url
      state.theme.config.logoImage = action.payload.logoImage
    },
    removeLogo(state: ThemeEditingState) {
      state.theme.logoUrl = undefined
      state.theme.config.logoImage = undefined
    },
  },
})

export const {
  resetThemeEditingState,
  updateThemeName,
  setBaseTheme,
  updateCardColor,
  updatePrimaryAccentColor,
  updateHeadingFont,
  updateBodyFont,
  updateHeadingFontWeight,
  updateBodyFontWeight,
  updateBodyColor,
  updateHeadingColor,
  updateConfig,
  updateLogo,
  updateFontPair,
  removeLogo,
} = ThemeEditingSlice.actions

type ThemeEditingSliceState = Pick<RootState, 'ThemeEditing'>

// Selectors
export const selectEditingTheme = (state: ThemeEditingSliceState) =>
  state.ThemeEditing.theme

export const selectThemeName = (state: ThemeEditingSliceState) =>
  state.ThemeEditing.theme.name

export const selectCreateThemeInput =
  (workspaceId: string) =>
  (state: ThemeEditingSliceState): CreateThemeInput => {
    return {
      workspaceId,
      name: state.ThemeEditing.theme.name,
      headingFont: state.ThemeEditing.theme.headingFont,
      headingFontWeight: state.ThemeEditing.theme.headingFontWeight,
      bodyFont: state.ThemeEditing.theme.bodyFont,
      bodyFontWeight: state.ThemeEditing.theme.bodyFontWeight,
      config: state.ThemeEditing.theme.config,
      priority: state.ThemeEditing.theme.priority,
    }
  }

export const ThemeEditingReducer = ThemeEditingSlice.reducer
