import {
  CloseButton,
  Flex,
  Heading,
  SkeletonText,
  Spacer,
  useColorMode,
} from '@chakra-ui/react'
import { faCompressAlt, faExpandAlt } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { GammaTooltip } from '@gamma-app/ui'
import { t, Trans } from '@lingui/macro'
import { JSONContent } from '@tiptap/core'
import { MouseEvent, useCallback } from 'react'
import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'
import { useSelector } from 'react-redux'

import { SegmentEvents, useAnalytics } from 'modules/segment'
import { selectMode } from 'modules/tiptap_editor/reducer'
import { useEditorContext } from 'sections/docs/context/EditorContext'
import { isMobileDevice } from 'utils/deviceDetection'
import { preventDefaultToAvoidBlur } from 'utils/handlers'

import { ToolbarIconButton } from '../Toolbar/components/ToolbarButton'
import { useSyncEditor } from './hooks'
import { TreeNavigation } from './Tree'

const SCROLLER_ELEMENT_CLASS = 'toc-root'

export const TableOfContents = ({
  handleClose,
  handleOpen,
  isOpen = true,
  rootElement,
  hasRoomToOpen = false,
  hasOpened = true,
}: {
  handleClose: () => void
  handleOpen?: () => void
  isOpen?: boolean
  rootElement: HTMLElement | null
  hasRoomToOpen?: boolean
  hasOpened?: boolean
}) => {
  const { editor, docId } = useEditorContext()
  const editorMode = useSelector(selectMode)

  const { colorMode } = useColorMode()
  const isDark = colorMode === 'dark'
  const analytics = useAnalytics()

  const { content, selection, isSelectionOriginTOC } = useSyncEditor(editor)

  const documentContents = content?.content as JSONContent[]
  const isContentLoaded = documentContents && hasOpened
  const canDock = !isOpen && handleOpen && hasRoomToOpen && !isMobileDevice()
  const canCollapse = !!isOpen && !isMobileDevice()

  const onTocExpand = useCallback(
    (e: MouseEvent) => {
      e.stopPropagation()
      analytics?.track(SegmentEvents.TOC_PINNED_OPEN, {
        mode: editorMode,
      })
      handleOpen?.()
    },
    [analytics, editorMode, handleOpen]
  )
  return (
    <Flex
      w="100%"
      h={!isOpen ? undefined : '100%'}
      direction="column"
      className="highlight-mask"
      data-table-of-contents
    >
      <Flex p={2} pb={0}>
        {isContentLoaded && (
          <Heading pt={3} px={6} fontSize={'lg'} position="relative">
            <Trans comment="e.g. Table of Contents">Contents</Trans>
          </Heading>
        )}
        <Spacer />
        {canDock ? (
          <GammaTooltip
            label={<Trans>Expand table of contents</Trans>}
            shortcut="Mod+\"
          >
            <ToolbarIconButton
              variant="ghost"
              aria-label={t`Expand table of contents`}
              data-testid="expand-toc"
              icon={<FontAwesomeIcon icon={faExpandAlt} />}
              onClick={onTocExpand}
              onMouseDown={preventDefaultToAvoidBlur}
            />
          </GammaTooltip>
        ) : canCollapse ? (
          <GammaTooltip
            label={<Trans>Collapse table of contents</Trans>}
            shortcut="Mod+\"
          >
            <ToolbarIconButton
              variant="ghost"
              aria-label={t`Collapse table of contents`}
              icon={<FontAwesomeIcon icon={faCompressAlt} />}
              onClick={(e) => {
                e.stopPropagation()
                handleClose()
              }}
              onMouseDown={preventDefaultToAvoidBlur}
            />
          </GammaTooltip>
        ) : null}
        {isMobileDevice() && (
          <CloseButton
            onClick={(e) => {
              e.stopPropagation()
              handleClose()
            }}
          />
        )}
      </Flex>
      <Flex
        className={SCROLLER_ELEMENT_CLASS}
        mr={1}
        mb={1}
        pr={1}
        overflowY="auto"
        flex={1}
        direction="column"
        position="relative"
        sx={{
          '.toc-box > .toc-box--inner': {
            pb: '2px',
            pt: '4px',
          },
          '.toc-box__below > .toc-box--inner': {
            pb: '0px',
          },
          // + is adjacent sibling css selector.  See: https://developer.mozilla.org/en-US/docs/Web/CSS/Adjacent_sibling_combinator
          '.toc-box__below + .toc-box > .toc-box--inner': {
            pt: '2px',
          },
          '.toc-box__above > .toc-box--inner': {
            pt: '0px',
          },
          '&::-webkit-scrollbar': {
            opacity: 1,
            width: '5px',
          },
          '&::-webkit-scrollbar-track': {
            opacity: 0,
            width: '5px',
          },
          '&::-webkit-scrollbar-thumb': {
            background: isDark ? 'whiteAlpha.500' : 'blackAlpha.400',
            borderRadius: 'full',
          },
        }}
      >
        {!isContentLoaded && (
          <SkeletonText
            p={6}
            skeletonHeight={'2rem'}
            mt={3}
            w={'100%'}
            noOfLines={8}
          />
        )}
        {isContentLoaded && rootElement && (
          <DndProvider
            backend={HTML5Backend}
            options={{
              rootElement,
            }}
          >
            {documentContents &&
              editor &&
              selection &&
              documentContents[0].content && (
                <TreeNavigation
                  node={editor.state.doc}
                  editorMode={editorMode}
                  isRoot={true}
                  editor={editor}
                  docId={docId}
                  cards={documentContents[0].content}
                  selection={selection}
                  isSelectionOriginTOC={isSelectionOriginTOC}
                  onClose={handleClose}
                  // `isOpen` means the TOC is sticky on the screen.
                  // `!isOpen` means the user sees the smaller version of the TOC, only on hover.
                  // Each version of the TOC requires a different scroll offset such that
                  // the scrolled-to card is always in view
                  scrollOffsetFromTop={isOpen ? 100 : 200}
                />
              )}
          </DndProvider>
        )}
      </Flex>
    </Flex>
  )
}
