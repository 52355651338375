import {
  FormControlProps,
  FormLabel,
  Slider,
  SliderFilledTrack,
  SliderMark,
  SliderThumb,
  SliderTrack,
  VStack,
} from '@chakra-ui/react'
import { Trans } from '@lingui/macro'

import { MaybeHex } from 'modules/theming/components/ColorPickerMenu/types'

import { ColorPalettePicker } from '../ColorPalettePicker'
import { LinearGradient } from './types'

export const DEFAULT_GRADIENT_COLOR = '#888888'
export const DEFAULT_ANGLE = 135 // Will show up well in vertical lines (blockquote) and horizontal (button)

type LinearGradientPickerProps = {
  value: LinearGradient | null
  updateValue: (value: LinearGradient) => void
} & FormControlProps

export const LinearGradientPicker = ({
  value,
  updateValue,
}: LinearGradientPickerProps) => {
  const updateColors = (colors: MaybeHex[]) => {
    updateValue({ ...value, colors })
  }

  const updateAngle = (angle: number) => {
    updateValue({ colors, angle })
  }

  const colors = value?.colors || [null, null]
  const displayAngle = value?.angle ?? DEFAULT_ANGLE

  return (
    <VStack spacing={2} align="flex-start">
      <ColorPalettePicker
        value={colors}
        updateValue={updateColors}
        maxColors={3}
        minColors={2}
        defaultValue={'#888888'}
      />
      <FormLabel mb="0">
        <Trans>Gradient angle</Trans>
      </FormLabel>
      <Slider
        aria-label="angle-slider"
        min={0}
        max={360}
        step={5}
        value={displayAngle}
        onChange={updateAngle}
        w="100%"
        colorScheme="trueblue"
        size="md"
        mt={'1.5rem !important'}
      >
        <SliderMark
          value={displayAngle}
          textAlign="center"
          mt={-8}
          ml={-3}
          fontSize="sm"
        >
          {displayAngle}°
        </SliderMark>
        <SliderTrack>
          <SliderFilledTrack />
        </SliderTrack>
        <SliderThumb />
      </Slider>
    </VStack>
  )
}
