import { SystemStyleObject } from '@chakra-ui/react'
import { CSSObject } from '@emotion/react'

import { DisplayLayout } from '../types'
import { CardLayoutItemIds } from './types'

type CardLayoutItemSpec = {
  type: 'body' | 'accent'
  style?: CSSObject
}

export type CardLayoutPreset = {
  grid: SystemStyleObject
  numColumns: number
  items: Partial<
    {
      [id in CardLayoutItemIds]: CardLayoutItemSpec
    }
  >
  stickyAccent?: boolean
  allowResizing?: boolean
  allowFit: boolean
}
export const MIN_ACCENT_HEIGHT = '24em'

export const SIDE_ACCENT_WIDTH = 0.375
export const SIDE_BODY_WIDTH = 1 - SIDE_ACCENT_WIDTH

export const LAYOUT_PRESETS: {
  [key in DisplayLayout]: CardLayoutPreset
} = {
  top: {
    grid: {
      gridTemplateColumns: '1fr',
      gridTemplateRows: `minmax(var(--top-accent-height), auto) 1fr`,
      gridTemplateAreas: `"accent" "body"`,
    },
    items: {
      accent: { type: 'accent' },
      body: { type: 'body' },
    },
    numColumns: 1,
    allowResizing: false,
    allowFit: false,
  },
  mobileLeft: {
    grid: {
      gridTemplateColumns: '1fr',
      gridTemplateRows: ['40vh auto'],
      gridTemplateAreas: `"accent" "body"`,
    },
    items: {
      accent: { type: 'accent' },
      body: { type: 'body' },
    },
    numColumns: 1,
    allowResizing: false,
    allowFit: true,
  },
  mobileRight: {
    grid: {
      gridTemplateColumns: '1fr',
      gridTemplateRows: ['auto 40vh'],
      gridTemplateAreas: `"body" "accent"`,
    },
    items: {
      accent: { type: 'accent' },
      body: { type: 'body' },
    },
    numColumns: 1,
    allowResizing: false,
    allowFit: true,
  },
  behind: {
    grid: {
      gridTemplateColumns: '1fr',
      gridTemplateRows: `minmax(var(--behind-accent-height, ${MIN_ACCENT_HEIGHT}), auto)`,
    },
    items: {
      accent: {
        type: 'accent',
        style: {
          gridArea: '1 / 1',
        },
      },
      body: {
        type: 'body',
        style: {
          gridArea: '1 / 1',
          zIndex: 1,
        },
      },
    },
    numColumns: 1,
    stickyAccent: true,
    allowResizing: false,
    allowFit: false,
  },
  blank: {
    grid: {
      gridTemplateColumns: '1fr',
      gridTemplateRows: '1fr',
      gridTemplateAreas: `"body"`,
    },
    items: {
      body: { type: 'body' },
    },
    numColumns: 1,
    allowResizing: false,
    allowFit: false,
  },
  left: {
    grid: {
      gridTemplateColumns: `${SIDE_ACCENT_WIDTH * 100}% ${
        SIDE_BODY_WIDTH * 100
      }%`,
      gridTemplateRows: `minmax(${MIN_ACCENT_HEIGHT}, auto)`,
      gridTemplateAreas: `"accent body"`,
    },
    items: {
      accent: { type: 'accent' },
      body: { type: 'body' },
    },
    numColumns: 2,
    stickyAccent: true,
    allowResizing: true,
    allowFit: true,
  },
  right: {
    grid: {
      gridTemplateColumns: `${SIDE_BODY_WIDTH * 100}% ${
        SIDE_ACCENT_WIDTH * 100
      }%`,
      gridTemplateRows: `minmax(${MIN_ACCENT_HEIGHT}, auto)`,
      gridTemplateAreas: `"body accent"`,
    },
    items: {
      accent: { type: 'accent' },
      body: { type: 'body' },
    },
    numColumns: 2,
    stickyAccent: true,
    allowResizing: true,
    allowFit: true,
  },
}
