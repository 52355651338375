import { PayloadAction, createSlice } from '@reduxjs/toolkit'

import { RootState } from 'modules/redux'
import { ACCENT_IMAGE_SOURCE_KEY } from 'modules/theming'
import { MediaSourcesMap } from 'modules/tiptap_editor/extensions/media/MediaSources'

type EditBackgroundDrawerState = {
  currentSourceKey: keyof typeof MediaSourcesMap
}

const initialState: EditBackgroundDrawerState = {
  currentSourceKey: ACCENT_IMAGE_SOURCE_KEY,
}

const EditBackgroundDrawerSlice = createSlice({
  name: 'EditBackgroundDrawer',
  initialState,
  reducers: {
    setCurrentSourceKey(
      state,
      action: PayloadAction<{ sourceKey: keyof typeof MediaSourcesMap }>
    ) {
      state.currentSourceKey = action.payload.sourceKey
    },
    resetCurrentSourceKey(state) {
      state.currentSourceKey = initialState.currentSourceKey
    },
  },
})

export const {
  setCurrentSourceKey: setBackgroundDrawerSourceKey,
  resetCurrentSourceKey: resetBackgroundDrawerSourceKey,
} = EditBackgroundDrawerSlice.actions

type SliceState = Pick<RootState, 'EditBackgroundDrawer'>

export const selectCurrentEditBackgroundDrawerSourceKey = (
  state: SliceState
): keyof typeof MediaSourcesMap => state.EditBackgroundDrawer.currentSourceKey

export const EditBackgroundDrawerReducer = EditBackgroundDrawerSlice.reducer
