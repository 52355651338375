import * as yaml from 'js-yaml'

import { getTextAmounts } from './constants'
import { TextAmount } from './types'
export const isContentEmpty = (contentMd: string) => {
  return contentMd.replaceAll('---', '').trim() === ''
}

export const countSectionDividers = (contentMd: string) => {
  const sections = contentMd.split('\n---\n')
  return sections.length
}

export const countContentParagraphs = (contentMd: string) => {
  const blocks = contentMd.split(/\n+/)
  return blocks.length
}

export const findTextAmount = (len: TextAmount) => {
  const TEXT_AMOUNTS = getTextAmounts()
  return TEXT_AMOUNTS[len] || TEXT_AMOUNTS.md
}

export const parseFencedCodeResponse = (response: string): string => {
  // Remove surrounding text and code fence if present
  const fencedCodeMatch = response.match(
    /```(?:html|xml|text|md)?\n([\s\S]*?)\n```/
  )
  const codeString = fencedCodeMatch ? fencedCodeMatch[1] : response
  // Parse the string
  return codeString.trim()
}
export const parseFencedYamlResponse = (response: string): any => {
  // Remove surrounding text and code fence if present
  const fencedCodeMatch = response.match(/```(?:yaml)?\n([\s\S]*?)\n```/)
  const yamlString = fencedCodeMatch ? fencedCodeMatch[1] : response

  // Parse the YAML string
  try {
    return yaml.load(yamlString)
  } catch (e) {
    throw `Failed to parse YAML response.
      Response:
      ${response}
      Error:
      ${e}
      `
  }
}
